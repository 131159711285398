/* eslint-disable array-callback-return */
import React from 'react';
import { withRouter } from 'react-router-dom';
import { debounce } from 'throttle-debounce';
import { connect } from 'react-redux';
import Spinner from 'react-bootstrap/Spinner';
import { Button } from 'react-bootstrap';
import { recordSetSearch, deleteRecordSet } from '../../pages/api/api';
import {
    GetFieldData,
    PatientWithUnderScore,
    formatHeaderNameWithOutSpace,
    normalSuccessToast,
    getDropDownDataFromApi,
    showErrorToast,
    encodeParameter,
} from '../commonfunction';
import { inboxPage } from '../../pages/_redux/actions/inboxActions';
import DeleteModal from './DeleteModal';
import { getDDLlist } from '../../pages/_redux/actions/configActions';

const _ = require('lodash');

export class ByPatient extends React.Component {
    constructor(props) {
        super(props);
        this.textInput = React.createRef();
        this.state = {
            autoCompleteSpinner: true,
            calingFrom: this.props.calingFrom,
            patient_filter: { patientname: '' },
            patient_filter_display:
                this.props.patient_filter_display !== undefined
                    ? this.props.patient_filter_display
                    : '',
            open_patient_delete: false,
            ...this.props.state,
            disableBtn: this.props.disableBtn ? this.props.disableBtn : false,
            tempPatientFilter: '',
        };
    }

    debounceFunc = debounce(1500, (num) => {
        this.patientApiCall(num);
    });
    async componentDidMount() {
        this.getTransitonDropDownData();
        this.configuredRecordsets();
        GetFieldData({ configData: this.props.configData });

        // 10098: Focus on patient input whenever we render this
        if (this.textInput.current) {
            this.textInput.current.focus();
        }

        if (
            _.isEmpty(this.state.patient_filter) === false &&
            this.state.patient_filter.hasOwnProperty('patient_name')
        ) {
            this.setState({
                patient_filter_display: this.state.patient_filter.patient_name,
            });
        }

        if (_.isEmpty(this.state.patient_filter) === false) {
            if (
                this.state.patient_filter.hasOwnProperty('patient_id') &&
                !this.state.patient_filter.hasOwnProperty('patientRecord')
            ) {
                this.searchSinglePatient(this.state.patient_filter.patient_id);
            } else if (this.state.patient_filter.hasOwnProperty('patientid')) {
                this.searchSinglePatient(this.state.patient_filter.patientid);
            }
        }

        if (
            _.isEmpty(this.state.patient_filter) === false &&
            this.state.patient_filter.hasOwnProperty('patientRecord') &&
            this.state.patient_filter.patientRecord === true &&
            !this.state.patient_filter.hasOwnProperty('order_number') &&
            this.state.isUploadPage !== true
        ) {
            this.open_patient_search(this.state.patient_filter, 'initialView');
            this.searchSinglePatient(
                this.state.patient_filter.patient_id,
                'initialView'
            );
        }

        document.addEventListener('mousedown', this.closePatientDropDown);
    }

    getTransitonDropDownData() {
        const fieldData = GetFieldData({ configData: this.props.configData });
        if (!this.props.ddl.hasOwnProperty('branch')) {
            getDropDownDataFromApi(fieldData.branch).then((data) => {
                if (data.values) {
                    this.props.getDDLlist({
                        [formatHeaderNameWithOutSpace(data.name)]: data.values,
                    });
                }
            });
        }
        if (!this.props.ddl.hasOwnProperty('serviceline')) {
            getDropDownDataFromApi(fieldData.service_line).then((data) => {
                if (data.values) {
                    this.props.getDDLlist({
                        [formatHeaderNameWithOutSpace(data.name)]: data.values,
                    });
                }
            });
        }
    }

    updateState = (state) => {
        this.props.updateState(state);
    };

    configuredRecordsets = async () => {
        let configuredRecordsets = [];
        let patientFieldIdArray;
        let modifyPatientFieldIdArray;
        let patientRecordsetId;
        let modifyPatientRecordsetId;
        let patientModifiableRecordsetId;
        let modifyPatientModifiableRecordsetId;
        let episodeFieldIdArray;
        let episodeRecordsetId;

        configuredRecordsets = this.props.configuredRecordsets;

        if (configuredRecordsets !== undefined) {
            configuredRecordsets.map((data) => {
                if (formatHeaderNameWithOutSpace(data.name) === 'patientdata') {
                    patientFieldIdArray = data.fields;
                    patientRecordsetId = data.recordsetId;
                    patientModifiableRecordsetId = data.modifiableRecordsetId;
                }
                if (
                    formatHeaderNameWithOutSpace(data.name) ===
                    'patientdatamodify'
                ) {
                    modifyPatientFieldIdArray = data.fields;
                    modifyPatientRecordsetId = data.recordsetId;
                    modifyPatientModifiableRecordsetId =
                        data.modifiableRecordsetId;
                }
                if (formatHeaderNameWithOutSpace(data.name) === 'episodedata') {
                    episodeFieldIdArray = data.fields;
                    episodeRecordsetId = data.recordsetId;
                }
                return data;
            });
        }

        await this.setState(
            {
                patientModifiableRecordsetId: patientModifiableRecordsetId,
                patientFieldIdArray: patientFieldIdArray,
                patientRecordsetId: patientRecordsetId,
                modifyPatientFieldIdArray: modifyPatientFieldIdArray,
                modifyPatientRecordsetId: modifyPatientRecordsetId,
                modifyPatientModifiableRecordsetId:
                    modifyPatientModifiableRecordsetId,
                edit_details: false,
                episodeFieldIdArray: episodeFieldIdArray,
                episodeRecordsetId: episodeRecordsetId,
            },
            () => {}
        );
    };

    searchSinglePatient = (patientId, view) => {
        let searchText = '';
        if (
            this.state.patientFieldIdArray !== '' &&
            this.state.patientFieldIdArray !== undefined
        ) {
            this.state.patientFieldIdArray.map((fieldData, index) => {
                if (
                    fieldData.name === 'PatientID' ||
                    fieldData.name === 'Patient ID'
                ) {
                    searchText += `fieldSearch=${fieldData.fieldId}%3D${patientId}%2A`;
                }
            });
        }
        let status;
        recordSetSearch(this.state.patientRecordsetId, searchText)
            .then((response) => {
                status = response.status;
                return response.json();
            })
            .then((data) => {
                if (status === 200) {
                    const searchResult = PatientWithUnderScore(data);
                    const singlePatient = PatientWithUnderScore(data);
                    if (view !== 'initialView') {
                        this.setState(
                            { currentSelectedPatient: singlePatient[0] },
                            () => {
                                this.props.updateState({
                                    currentSelectedPatient: singlePatient[0],
                                });
                            }
                        );

                        if (searchResult.length > 0) {
                            searchResult[0].branch = this.props.documentBranch
                                ? this.props.documentBranch
                                : this.state.patient_filter.branch;
                            this.open_patient_search(searchResult[0]);
                        }
                    } else {
                        let search = {};
                        if (singlePatient.length > 0) {
                            search = singlePatient[0];
                        }

                        if (search.hasOwnProperty('id')) {
                            const temp = {
                                ...this.state.patient_filter,
                                id: search.id,
                                branch: this.props.documentBranch
                                    ? this.props.documentBranch
                                    : this.state.patient_filter.branch,
                            };
                            this.setState({
                                patient_filter: temp,
                                tempPatientFilter: temp,
                            });
                            this.props.updateState({ patient_filter: temp });
                        }

                        //if (search.id > 0 || search.id === 0) {
                        if (search.isModifiable) {
                            this.setState(
                                {
                                    patientIsNegative: false,
                                },
                                () => {
                                    this.props.updateState({
                                        patientIsNegative: false,
                                    });
                                }
                            );
                        } else {
                            this.setState(
                                {
                                    patientIsNegative: true,
                                },
                                () => {
                                    this.props.updateState({
                                        patientIsNegative: true,
                                    });
                                }
                            );
                        }
                    }
                }
            });
    };

    closePatientDropDown = (event) => {
        if (event.target.id !== 'patient_inputbox') {
            const popElement =
                document.getElementsByClassName('patient_dropdown');
            let popEl;
            for (let i = 0; i < popElement.length; i++) {
                popEl = popElement[i];
                const isClickInside = popEl.contains(event.target);
                if (!isClickInside) {
                    this.setState({
                        open_patient_dropdown: false,
                        patient_filter: '',
                        patient_filter_display: '',
                    });
                    this.setState({
                        open_order_dropdown: false,
                        order_filter: '',
                    });
                    return;
                }
            }
        }
    };

    componentDidUpdate(prevProps) {
        if (
            (this.props.referralsUsed === true ||
                this.props.referralsUsed === false) &&
            this.props.state.patient_filter.hasOwnProperty('patient_id') &&
            this.props.enableSingleSearch === true
        ) {
            this.updateState({
                enableSingleSearch: false,
            });
            this.searchSinglePatient(
                this.props.state.patient_filter.patient_id,
                'not'
            );
        }
        if (this.props.editPatient !== prevProps.editPatient) {
            this.setState({ edit_patient: this.props.editPatient });
        }

        if (this.props.createNormalPatient !== prevProps.createNormalPatient) {
            this.setState({ create_patient: this.props.createNormalPatient });
        }

        if (this.props.state !== prevProps.state) {
            if (
                this.state.patient_filter_display !==
                this.props.state.patient_filter_display
            )
                if (this.props.closeFunction === true) {
                    this.setState({
                        open_create_patient:
                            this.props.state.open_create_patient,
                        patient_filter: this.props.state.patient_filter,
                        view_patient: this.props.state.view_patient,
                        patientIsNegative: this.props.state.patientIsNegative,
                        tempPatientFilter: this.props.state.tempPatientFilter,
                        patient_filter_display:
                            this.props.state.patient_filter_display,
                    });

                    this.props.updateState({
                        closeFunction: false,
                    });
                    this.props.toggleRecentDocFun();
                }
        }
    }

    async byPatientOnChangeHandler(e) {
        this.setState({
            patient_filter_display: e.target.value,
        });
        this.debounceFunc(e.target.value);
    }
    async patientApiCall(text) {
        const currentValue = text.split('');
        if (currentValue.length <= 0) {
            this.setState({
                open_patient_dropdown: false,
                patient_filter: { patientname: text },
            });
            return true;
        }
        this.setState({
            open_patient_dropdown: false,
            patient_filter: { patientname: text },
            autoCompleteSpinner: true,
        });
        let searchText = '';

        let fieldId = GetFieldData({
            configData: this.props.configData,
        });

        if (
            this.props.state.currentLookupPatient === undefined ||
            this.props.state.currentLookupPatient === 'byPatient'
        ) {
            searchText = `fieldSearch=${
                fieldId.patient_name
            }%3D${encodeParameter(text)}%2A`;
            this.patient_api_call(searchText);
        } else if (
            this.props.state.currentLookupPatient !== undefined &&
            this.props.state.currentLookupPatient === 'byMRNumber'
        ) {
            var episearchText = `fieldSearch=${fieldId.medical_record_}%3D${text}%2A`;
            var epistatus;
            await recordSetSearch(this.state.episodeRecordsetId, episearchText)
                .then((response) => {
                    epistatus = response.status;
                    return response.json();
                })
                .then((data) => {
                    if (epistatus === 200) {
                        const episearchResult = PatientWithUnderScore(data);
                        var patient_result = [];
                        var uniqueRow = [];
                        if (episearchResult.length > 0) {
                            episearchResult.map((search, index) => {
                                if (!uniqueRow.includes(search.patient_id)) {
                                    uniqueRow.push(search.patient_id);
                                    let psearchText = `fieldSearch=${fieldId.patient_id}%3D${search.patient_id}%2A`;
                                    var vstatus;
                                    recordSetSearch(
                                        this.state.patientRecordsetId,
                                        psearchText
                                    )
                                        .then((response) => {
                                            vstatus = response.status;
                                            return response.json();
                                        })
                                        .then((data) => {
                                            if (vstatus === 200) {
                                                const searchResult =
                                                    PatientWithUnderScore(data);
                                                // const result =
                                                searchResult.reduce(function (
                                                    r,
                                                    a,
                                                    c
                                                ) {
                                                    r[a.patient_id] =
                                                        r[a.patient_id] || [];
                                                    r[a.patient_id].push(a);
                                                    return r;
                                                },
                                                Object.create(null));

                                                patient_result.push(
                                                    searchResult[0]
                                                );
                                            }
                                            if (vstatus !== 200 && vstatus) {
                                                this.setState({
                                                    autoCompleteSpinner: false,
                                                });
                                                showErrorToast(
                                                    'An error has occured while calling the API'
                                                );
                                            }
                                        });
                                }
                            });

                            setTimeout(() => {
                                if (patient_result.length > 0) {
                                    this.setState({
                                        patient_list: patient_result,
                                        autoCompleteSpinner: false,
                                    });
                                }
                            }, 2000);
                        } else {
                            setTimeout(() => {
                                this.setState({
                                    autoCompleteSpinner: false,
                                });
                            }, 2000);
                        }
                    }
                });
        }

        this.setState({
            open_patient_dropdown: true,
            patient_list: [],
        });
    }
    patient_api_call = async (searchText) => {
        let status;

        await recordSetSearch(this.state.patientRecordsetId, searchText)
            .then((response) => {
                if (response !== undefined && response.status !== undefined) {
                    status = response.status;
                    return response.json();
                }
            })
            .then((data) => {
                if (status === 200) {
                    const searchResult = PatientWithUnderScore(data);
                    searchResult.reduce(function (r, a, c) {
                        r[a.patient_id] = r[a.patient_id] || [];
                        r[a.patient_id].push(a);
                        return r;
                    }, Object.create(null));

                    this.setState({
                        patient_list: searchResult,
                        autoCompleteSpinner: false,
                    });
                }
            });
    };
    patient_list = () => {
        if (this.state.patient_list.length > 0) {
            return this.state.patient_list.map((search, index) => {
                // Checking
                if (search == undefined || search == null) {
                    return;
                }

                let res = search.patient_name;

                const req = [this.state.patient_filter_display];
                if (req) {
                    res = res.replace(
                        new RegExp(`(${req.join('|')})`, 'i'),
                        (match) => `<b class='highlight'>${match}</b>`
                    );
                }

                const custom_name = `${res}`;
                return (
                    <React.Fragment key={index}>
                        <li
                            onClick={() => {
                                this.open_patient_search(
                                    search,
                                    'Autocomplete'
                                );
                            }}
                        >
                            <div>
                                <div className='patient_autocomplete'>
                                    <div>
                                        <div className='patient_no_name'>
                                            <span
                                                className='first_name'
                                                dangerouslySetInnerHTML={{
                                                    __html: custom_name,
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div className='filter-date text-right'>
                                        <span>{search.formatteddob}</span>
                                    </div>
                                </div>
                                <div className='patient_autocomplete'>
                                    <div>
                                        <div className='patient_status'>
                                            <span>Episode Status :</span>
                                            <span className='status'>
                                                {search.episode_status}
                                            </span>
                                        </div>
                                    </div>
                                    <div className='filter-date text-right'>
                                        <div className='patient_status'>
                                            <span>Branch :</span>
                                            <span className='status'>
                                                {search.branch}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </li>
                    </React.Fragment>
                );
            });
        } else {
            return <div style={{ textAlign: 'center' }}>No Records Found</div>;
        }
    };

    open_patient_search = async (search, view) => {
        let state;
        if (this.props.calingFrom === 'dashboard') {
            let path = '/search/patients/?ID=' + search.patient_id;
            this.props.history.push(path);
        } else {
            state = {
                patient_filter: search,
                tempPatientFilter: search,
                open_patient_dropdown: false,
                view_patient: false,
                selected_patient_id: search.patientid,
                open_create_patient: false,
                create_patient: false,
                edit_patient: false,
            };

            if (search.hasOwnProperty('patient_name')) {
                state.patient_filter_display = search.patient_name;
            } else {
                if (
                    search.hasOwnProperty('first_name') &&
                    search.hasOwnProperty('last_name')
                ) {
                    state.patient_filter_display = `${search.last_name}, ${search.first_name}`;
                }
            }

            this.setState({
                patient_filter: {
                    patientname: state.patient_filter_display,
                },
                patient_filter_display: state.patient_filter_display,
            });
            if (this.props.patientLink === true) {
                this.updateState({
                    patientLink: false,
                });
            }
            if (this.props.newStateCalling === 'upload') {
                this.props.PatientLink_Function(false);
            }

            this.setState(state, () => {
                this.props.updateState(state);
            });

            if (view !== 'initialView') {
                if (search.isModifiable) {
                    await this.setState(
                        {
                            patientIsNegative: false,
                        },
                        () => {
                            this.props.updateState({
                                patientIsNegative: false,
                            });
                        }
                    );
                } else {
                    await this.setState(
                        {
                            patientIsNegative: true,
                        },
                        () => {
                            this.props.updateState({ patientIsNegative: true });
                        }
                    );
                }
            }

            await this.setState({ view_patient: true }, () => {
                this.props.updateState({
                    view_patient: true,
                    toggleRecentDoc: true,
                    togglePatient: true,
                });
            });
            if (this.state.calingFrom === 'search') {
                this.props.toggleRecentDocFun();
            }
        }
    };

    handleCloseOperation = async () => {
        this.setState({ open_patient_delete: false });
    };

    handlePatientDeleteOperation = async () => {
        let status;
        const multipleIds = [];
        const recordSetId = this.state.patientModifiableRecordsetId;
        const patientId = Math.abs(this.state.patient_filter.id);
        deleteRecordSet(multipleIds, recordSetId, patientId)
            .then((response) => {
                status = response.status;
                if (status === 204) {
                    this.setState({
                        patient_filter: '',
                        open_patient_dropdown: false,
                        view_patient: false,
                        selected_patient_id: '',
                        open_create_patient: false,
                        create_patient: false,
                        edit_patient: false,
                        patient_filter_display: '',
                    });
                    this.props.updateState({
                        patient_filter: '',
                        open_patient_dropdown: false,
                        view_patient: false,
                        selected_patient_id: '',
                        open_create_patient: false,
                        create_patient: false,
                        edit_patient: false,
                        patient_filter_display: '',
                    });
                    normalSuccessToast('Patient Deleted Successfully');
                    this.setState({ open_patient_delete: false });
                } else if (response !== '') {
                    return response.json();
                }
            })
            .then((data) => {
                if (status === 400 || status === 404) {
                }
            });
    };

    deletePatient = () => {
        if (this.state.patient_filter.id !== undefined) {
            this.setState({ open_patient_delete: true });
        }
    };

    edit_patient = () => {
        this.props.updateState({
            edit_patient: false,
            open_edit_patient: false,
        });
        this.props.updateState({ edit_patient: true, open_edit_patient: true });
        this.setState({ edit_patient: true }, () => {});
    };

    openCreateNewPatient = async () => {
        if (this.props.calingFrom === 'dashboard') {
            this.props.history.push('/search/patients/?operation=add');
        } else {
            this.props.updateState({
                openCreatePopup: false,
                patient_filter: {
                    patient_name: '',
                },
                open_create_patient: true,
                patient_filter_display: '',
            });
            const state = {
                open_create_patient: true,
                edit_patient: false,
                view_patient: false,
                open_patient_dropdown: false,
                open_order_dropdown: false,
                openCreatePopup: true,
                formFor: 'patient',
                patient_filter_display: '',
            };
            this.setState(state, () => {
                this.props.updateState(state);
            });
        }
    };

    submitEditPatient = () => {
        this.props.submitEditPatient();
        this.setState({ submitEditPatient: true }, () => {
            this.props.updateState({
                submitEditPatient: true,
            });
        });
    };

    submitNormalCreatePatient = () => {
        this.props.submitNormalCreatePatient();
        this.setState({ submitNormalCreatePatient: true }, () => {
            this.props.updateState({ submitNormalCreatePatient: true });
        });
    };

    close_edit = async () => {
        await this.setState({ edit_patient: false }, () => {
            this.props.updateState({ edit_patient: false });
        });
        await this.props.updateState({
            patient_filter: this.state.tempPatientFilter,
            cancelSearch: true,
        });
        await this.setState({
            patient_filter: this.state.tempPatientFilter,
        });
    };

    close_save = () => {
        this.setState({ open_create_patient: false }, () => {
            this.props.updateState({ open_create_patient: false });
        });
    };

    clearInputBox = () => {
        this.setState({
            patient_filter_display: '',
        });
        this.textInput.current.focus();
    };

    render() {
        return (
            <>
                <div className='col-lg-12 col-xl-8 detail_search'>
                    <label
                        className={
                            this.props.calingFrom === 'search' &&
                            this.props.toggleRecentDoc === false
                                ? ' hide-data pl-5'
                                : 'pl-5'
                        }
                    >
                        {this.props.calingFrom === 'outbox'
                            ? 'PATIENT'
                            : this.props.calingFrom === 'search' &&
                              this.props.toggleRecentDoc !== false
                            ? 'LOOKUP'
                            : this.props.calingFrom === 'inbox'
                            ? 'LOOKUP'
                            : null}
                    </label>
                    <div className='d-flex'>
                        {this.props.toggleRecentDoc === false ? (
                            <div
                                className='row'
                                onClick={() => this.props.toggleRecentDocFun()}
                            >
                                <div className='mr-4'>
                                    <label>LOOKUP</label>
                                </div>
                                <div className='d-flex align-items-center'>
                                    <h3 className='title m-0'>
                                        {this.state.patient_filter_display
                                            .charAt(0)
                                            .toUpperCase() +
                                            this.state.patient_filter_display.slice(
                                                1
                                            )}
                                    </h3>
                                </div>
                            </div>
                        ) : null}
                        {this.props.toggleRecentDoc === undefined ||
                        this.props.toggleRecentDoc === true ? (
                            <div className='filter'>
                                <input
                                    className={
                                        this.props.patientLink ||
                                        this.props.uploadPatientLink
                                            ? 'form-control error-field'
                                            : 'form-control'
                                    }
                                    value={this.state.patient_filter_display}
                                    placeholder={
                                        this.props.state
                                            .currentLookupPatient !==
                                            undefined &&
                                        this.props.state
                                            .currentLookupPatient ===
                                            'byMRNumber'
                                            ? 'Type to search by MR Number'
                                            : this.props.calingFrom ===
                                              'dashboard'
                                            ? 'Lookup by Patient Name'
                                            : 'Type to search by Patient'
                                    }
                                    type='text'
                                    onChange={this.byPatientOnChangeHandler.bind(
                                        this
                                    )}
                                    ref={this.textInput}
                                    id='patient_inputbox'
                                    disabled={this.props.isLocked}
                                />

                                {this.state.patient_filter_display !==
                                    undefined &&
                                this.state.patient_filter_display !== '' &&
                                this.state.patient_filter_display.length > 0 ? (
                                    <i
                                        className='fa-regular fa-xmark'
                                        onClick={this.clearInputBox}
                                    />
                                ) : (
                                    <i className='fa-regular fa-magnifying-glass' />
                                )}
                                {this.state.open_patient_dropdown ? (
                                    <div
                                        className={
                                            (this.props.rulesJson.hasOwnProperty(
                                                'indexingSettings'
                                            ) &&
                                                this.props.rulesJson
                                                    .indexingSettings
                                                    .standAloneUsed) ||
                                            this.state.isUploadPage
                                                ? 'patient_dropdown'
                                                : 'no_create patient_dropdown'
                                        }
                                        style={{
                                            height:
                                                this.state.patient_list.length >
                                                0
                                                    ? '248px'
                                                    : '80px',
                                        }}
                                    >
                                        <ul
                                            style={{
                                                listStyleType: 'none',
                                                overflowY: 'scroll',
                                                height:
                                                    this.state.patient_list
                                                        .length > 0
                                                        ? '200px'
                                                        : '',
                                            }}
                                        >
                                            {this.state.autoCompleteSpinner ? (
                                                <div
                                                    style={{
                                                        textAlign: 'center',
                                                    }}
                                                >
                                                    <Spinner
                                                        animation='grow'
                                                        variant='primary'
                                                    />
                                                </div>
                                            ) : (
                                                this.patient_list()
                                            )}
                                        </ul>
                                        <br />
                                        {(this.props.rulesJson.hasOwnProperty(
                                            'indexingSettings'
                                        ) &&
                                            this.props.rulesJson
                                                .indexingSettings
                                                .standAloneUsed) ||
                                        (this.props.rulesJson.hasOwnProperty(
                                            'indexingSettings'
                                        ) &&
                                            this.props.rulesJson
                                                .indexingSettings
                                                .standAloneUsed) ||
                                        (this.state.isUploadPage &&
                                            this.props.rulesJson.hasOwnProperty(
                                                'indexingSettings'
                                            ) &&
                                            this.props.rulesJson
                                                .indexingSettings
                                                .standAloneUsed) ? (
                                            <div
                                                className='adding_patient'
                                                onClick={() => {
                                                    this.openCreateNewPatient();
                                                }}
                                                style={{ cursor: 'pointer' }}
                                            >
                                                <div className='plus'>
                                                    <span>
                                                        <i
                                                            className='fa-regular fa-plus'
                                                            aria-hidden='true'
                                                        />
                                                    </span>
                                                </div>
                                                Create New Patient
                                            </div>
                                        ) : null}
                                    </div>
                                ) : null}
                            </div>
                        ) : null}

                        {this.props.rulesJson.hasOwnProperty(
                            'indexingSettings'
                        ) &&
                        this.props.rulesJson.indexingSettings.standAloneUsed &&
                        this.state.view_patient !== true && // added by kalpana - to have only edit button and not having add button whenw we are in edit mode
                        this.state.open_create_patient !== true &&
                        this.state.edit_patient !== true &&
                        (this.props.calingFrom === 'outbox' ||
                            this.props.calingFrom === 'search') ? (
                            <div className='create'>
                                {this.state.calingFrom === 'search' ? (
                                    <Button
                                        variant='primary'
                                        className='success'
                                        onClick={() => {
                                            this.openCreateNewPatient();
                                        }}
                                        title='Add New Patient'
                                    >
                                        <i className='fa-regular fa-plus' /> Add
                                        New Patient
                                    </Button>
                                ) : (
                                    <div
                                        className='plus'
                                        onClick={() => {
                                            this.openCreateNewPatient();
                                        }}
                                    >
                                        <span>
                                            <i
                                                className='fa-regular fa-plus'
                                                aria-hidden='true'
                                            />
                                        </span>
                                    </div>
                                )}
                            </div>
                        ) : null}

                        {this.state.view_patient &&
                        this.state.edit_patient === false &&
                        this.state.patientIsNegative === false &&
                        this.state.modifyPatientModifiableRecordsetId !==
                            null ? (
                            <>
                                {this.state.calingFrom === 'search' &&
                                this.props.toggleRecentDoc === true ? (
                                    <div className='save_cancel'>
                                        <Button
                                            variant='primary'
                                            className='success'
                                            onClick={this.edit_patient}
                                            title='Edit Patient'
                                        >
                                            Edit Patient
                                        </Button>
                                    </div>
                                ) : (
                                    <>
                                        {this.state.calingFrom !== 'search' && (
                                            <div className='plus'>
                                                <span
                                                    className='edit_btn'
                                                    onClick={this.edit_patient}
                                                >
                                                    <i className='fas fa-pencil-alt' />
                                                </span>
                                            </div>
                                        )}
                                    </>
                                )}
                            </>
                        ) : null}
                        {this.state.edit_patient === true &&
                        (this.state.calingFrom !== 'search' ||
                            this.state.calingFrom === 'outbox') ? (
                            <div
                                className='plus'
                                id='submitEditPatient'
                                onClick={this.submitEditPatient}
                            >
                                <span>
                                    <i className='fas fa-check-square' />
                                </span>
                            </div>
                        ) : this.state.edit_patient === true &&
                          this.state.calingFrom === 'search' &&
                          this.props.toggleRecentDoc === true ? (
                            <>
                                <div className='save_cancel'>
                                    <Button
                                        // variant='primary'
                                        className='clear cancel-bg-btn'
                                        onClick={this.deletePatient}
                                        title='Delete'
                                    >
                                        <i
                                            title='Delete'
                                            className='fas fa-trash-alt'
                                        />
                                    </Button>
                                    <Button
                                        variant='secondary'
                                        className='btn-sav'
                                        onClick={this.close_edit}
                                        title='Cancel'
                                    >
                                        Cancel
                                    </Button>
                                    <Button
                                        variant='success'
                                        className='btn-sav'
                                        onClick={this.submitEditPatient}
                                        title='Save'
                                    >
                                        Save
                                    </Button>
                                </div>
                            </>
                        ) : this.state.open_create_patient === true &&
                          this.state.calingFrom === 'bigView' ? (
                            <div
                                className='plus'
                                id='submitNormalCreatePatient'
                                onClick={
                                    this.state.disableBtn === true
                                        ? null
                                        : this.submitNormalCreatePatient
                                }
                            >
                                <span>
                                    <i className='fas fa-check-square' />
                                </span>
                            </div>
                        ) : this.state.open_create_patient === true &&
                          this.state.calingFrom === 'search' ? (
                            <>
                                <div className='save_cancel'>
                                    <Button
                                        variant='secondary'
                                        onClick={this.close_save}
                                        title='Cancel'
                                    >
                                        Cancel
                                    </Button>
                                    <Button
                                        variant='success'
                                        onClick={
                                            this.state.disableBtn === true
                                                ? null
                                                : this.submitNormalCreatePatient
                                        }
                                        title='Save'
                                    >
                                        Save
                                    </Button>
                                </div>
                            </>
                        ) : null}
                    </div>
                </div>

                {this.state.open_patient_delete ? (
                    <DeleteModal
                        handlePatientDeleteOperation={
                            this.handlePatientDeleteOperation
                        }
                        handleCloseOperation={this.handleCloseOperation}
                        openDelete
                    />
                ) : null}
            </>
        );
    }
}

const mapDispatchToProps = {
    inboxPage,
    getDDLlist: getDDLlist,
};
const mapStateToProps = (state, ownProps) => {
    return {
        ddl: state.persistDDL.DDL,
        rulesJson: state.configReducerPersist.configData,
        selectedRowDetails: state.inbox.selectedRowDetails,
        configData: state.configReducerPersist.configData,
        configuredRecordsets:
            state.configReducerPersist.configData.configuredRecordsets,
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(ByPatient));
