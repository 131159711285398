/* eslint-disable array-callback-return */
import React from 'react';
// import DatePicker from 'react-date-picker';
// import { Button } from 'react-bootstrap';
import { Toast } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { recordSetSearch, getCount } from '../../api/api';
import DeliveryDetailModal from './DeliveryDetailModal';
import {
    GetFieldData,
    formatHeaderNameWithOutSpace,
    formatNameWithSpaceToUnderscore,
    EpisodeSearch,
} from '../../../components/commonfunction';
import OutboxActionsComponent from './OutboxActionsComponent';
import CoverPageModal from './CoverPageModal';
import PrintPdfDocument from './PrintPdfDocument';
import { Notes } from './Notes';
import { ViewAllNotes } from './ViewAllNotes';
import {
    getListaction,
    setSelectedRow,
    getRulesJson,
    showLoader,
    hideBigView,
    getSelectedRowDetails,
    updateOutboxState,
} from '../../_redux/actions/outboxActions';
import { getDDLlist } from '../../_redux/actions/configActions';
import RelatedDocument from './RelatedDocument';
import DetailSection from './DetailSection';

toast.configure();
const toastElement = (
    closeFn,
    canShow = false,
    headerTxt = '',
    messageText = ''
) => {
    return (
        <Toast show={canShow} onClose={closeFn} delay={3000} autohide>
            <Toast.Header>{headerTxt}</Toast.Header>
            <Toast.Body>{messageText}</Toast.Body>
        </Toast>
    );
};
class DetailBigView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            is_add_notes: '',
            notes_details: 1,
            notes_list: [],
            showToastMsg: false,
            printElement: undefined,
            openHistroy: false,
            toggleRecentDoc: false,
            detailBigView: false,
            open_create_patient: false,
            patient_list: [],
            order_list: [],
            patient_filter: '',
            order_filter: '',
            open_patient_dropdown: false,
            view_patient: false,
            edit_patient: false,
            create_order: false,
            by_patient: true,
            by_order: false,
            edit_details: '',
            printSelectedDocId: [],
            printLoader: false,
            viewAllNotes: true,
            editId: '',
            detailPage:
                this.props.detailView !== undefined
                    ? this.props.detailView
                    : false,
            rulesJson: {},
            emr_order_typeDDL: [],
            attachmentLocationDDL: [],
            newreferralDDL: [],
            edit_order: false,
            orderModifiableRecordsetId: '',
            patientModifiableRecordsetId: '',
            detailDDL: 'Attach to chart',
            formData: {
                attachment_type: '',
                attachment_location: '',
                date_of_birth_validation: '',
                new_referral: '',
                team: '',
            },
            submitDetail: false,
            validateDetail: false,
            allActions: this.props.outbox.stateData.allActions,
            pageCount: this.props.outbox.selectedRowDetails.hasOwnProperty(
                'miscPproperties'
            )
                ? this.props.outbox.selectedRowDetails.miscPproperties.pageCount
                : 0,
            isLocked:
                this.props.calingFrom !== 'deleted_document' &&
                this.props.outbox.selectedRowDetails.hasOwnProperty(
                    'miscPproperties'
                )
                    ? this.props.outbox.selectedRowDetails.miscPproperties
                          .isLocked
                    : this.props.calingFrom === 'deleted_document'
                    ? this.props.isLocked
                    : false,
            open_edit_details: false,
            submittingViaUpload: false,
            // patientCreated: false,
            isDetailBigView: true,
            open_delivery_detail_section: true,
            selectedDocId: 0,
            refreshRD: false,
        };
        this.myRef = React.createRef();
    }

    /* addEditDetails = () => {
        this.setState({
            open_edit_details: true,
        });
    }; */

    async outboxrefreshTable() {
        // detailBigView: false,
        this.props.updateState({
            flag_bigView: true,
            detailBigView: false,
        });

        const processId =
            this.props.outbox.selectedRowDetails.statuses.processId !==
            undefined
                ? this.props.outbox.selectedRowDetails.statuses.processId
                : this.props.outbox.selectedRowDetails.statuses[0].processId;
        const statusId =
            this.props.outbox.selectedRowDetails.statuses.statusId !== undefined
                ? this.props.outbox.selectedRowDetails.statuses.statusId
                : this.props.outbox.selectedRowDetails.statuses[0].statusId;

        await getCount(processId, statusId)
            .then((response) => {
                return response.json();
            })
            .then((data) => {
                localStorage.setItem('refresh_count', data.count);
                let countData = localStorage.getItem(
                    `CountData${data.processId}`
                );
                if (countData !== null) {
                    let totalCount = 0;
                    countData = JSON.parse(countData);
                    if (countData.statuses !== undefined) {
                        countData.statuses.map((arrayval) => {
                            if (
                                arrayval.processId === data.processId &&
                                arrayval.statusId === data.statusId
                            ) {
                                arrayval.count = data.count;
                            }
                            totalCount += arrayval.count;
                            return arrayval;
                        });
                    }
                    if (countData.process !== undefined) {
                        countData.process.count = totalCount;
                    }
                    localStorage.setItem(
                        `CountData${data.processId}`,
                        JSON.stringify(countData)
                    );
                }
            });
        this.props.outboxrefreshTable();
    }

    set_handlenotessubmit = async (notes, notesValue, notesDDL, docId) => {
        this.setState({ is_add_notes: 0 });
        await this.props.handleNotesSubmit(notes, notesValue, notesDDL, docId);
        window.scrollTo(0, document.body.scrollHeight);
    };

    open_notes_section = () => {
        this.setState({
            open_notes_section: true,
            is_add_notes: 1,
        });
    };

    viewAllNotes = async () => {
        await this.setState({ viewAllNotes: !this.state.viewAllNotes });
    };
    componentDidUpdate() {
        if (
            this.props.selectedDocId !== undefined &&
            Number(this.props.selectedDocId) !==
                Number(this.state.selectedDocId)
        ) {
            this.setState({
                selectedDocId: this.props.selectedDocId,
                refreshRD: true,
            });
            setTimeout(() => {
                this.setState({
                    refreshRD: false,
                });
            }, 1000);
        }
    }

    async componentDidMount() {
        window.addEventListener('beforeunload', this.onUnload);
        if (this.props.calingFrom === 'deleted_document') {
            this.setState({
                allActions: this.props.allActions,
            });
            this.props.updateOutboxState({
                // comment by geetha
                /* stateData: {
                    allActions: {},
                }, */
            });
        }
        const fieldDataObj = GetFieldData({
            configData: this.props.configData,
        });
        this.setState({ allFieldIds: fieldDataObj });

        if (this.props.outbox.selectedRowDetails.hasOwnProperty('fields')) {
            this.props.outbox.selectedRowDetails.fields.map((rowData) => {
                const fields = rowData.fieldId;

                if (String(fields) === String(fieldDataObj.order_number)) {
                    this.setState({
                        order_number: rowData.value,
                    });
                } else if (String(fields) === String(fieldDataObj.order_date)) {
                    this.setState({
                        order_date: new Date(rowData.value),
                    });
                } else if (
                    String(fields) === String(fieldDataObj.emr_order_type)
                ) {
                    this.setState({
                        order_type: rowData.value,
                    });
                }
            });
        }
        if (this.props.open_edit_details !== undefined) {
            this.setState({ open_edit_details: this.props.open_edit_details });
        }

        const pushEditId = [];
        pushEditId.push(window.btoa(String(this.props.selectedDocId)));
        const editURL = `/documents/edit/${pushEditId}`;

        if (this.state.pageCount > 0) {
            this.setState({ editId: editURL });
        } else {
            this.setState({ editId: '' });
        }
        let configuredRecordsets = [];
        let patientFieldIdArray;
        let modifyPatientFieldIdArray;
        let orderFieldIdArray;
        let modifyOrderFieldIdArray;
        let patientRecordsetId;
        let modifyPatientRecordsetId;
        let orderRecordsetId;
        let modifyOrderRecordsetId;
        let patientModifiableRecordsetId;
        let modifyPatientModifiableRecordsetId;
        let orderModifiableRecordsetId;
        let modifyOrderModifiableRecordsetId;
        let physicianFieldIdArray;
        let physicianRecordsetId;
        let physicianModifiableRecordsetId;
        // let episodeRecordsetId;
        // let episodevisitRecordsetId;
        // var modifyPhysicianFieldIdArray, modifyPhysicianRecordsetId, modifyPhysicianModifiableRecordsetId;

        configuredRecordsets = this.props.configuredRecordsets;
        const allRecordSetID = {};
        if (configuredRecordsets !== undefined) {
            configuredRecordsets.map((data) => {
                if (formatHeaderNameWithOutSpace(data.name) === 'patientdata') {
                    patientFieldIdArray = data.fields;
                    patientRecordsetId = data.recordsetId;
                    patientModifiableRecordsetId = data.modifiableRecordsetId;
                }
                if (
                    formatHeaderNameWithOutSpace(data.name) ===
                    'patientdatamodify'
                ) {
                    modifyPatientFieldIdArray = data.fields;
                    modifyPatientRecordsetId = data.recordsetId;
                    modifyPatientModifiableRecordsetId =
                        data.modifiableRecordsetId;
                }
                if (formatHeaderNameWithOutSpace(data.name) === 'orderdata') {
                    orderFieldIdArray = data.fields;
                    orderRecordsetId = data.recordsetId;
                    orderModifiableRecordsetId = data.modifiableRecordsetId;
                }
                if (
                    formatHeaderNameWithOutSpace(data.name) ===
                    'orderdatamodify'
                ) {
                    modifyOrderFieldIdArray = data.fields;
                    modifyOrderRecordsetId = data.recordsetId;
                    modifyOrderModifiableRecordsetId =
                        data.modifiableRecordsetId;
                }
                if (
                    formatHeaderNameWithOutSpace(data.name) ===
                    'physiciandirectory'
                ) {
                    physicianFieldIdArray = data.fields;
                    physicianRecordsetId = data.recordsetId;
                    physicianModifiableRecordsetId = data.modifiableRecordsetId;
                }
                if (formatHeaderNameWithOutSpace(data.name) === 'episodedata') {
                    // episodeRecordsetId = data.recordsetId;
                    let status;
                    recordSetSearch(data.recordsetId)
                        .then((response) => {
                            status = response.status;
                            return response.json();
                        })
                        .then((data) => {
                            if (status === 200) {
                                const searchResult = EpisodeSearch(data);
                                this.setState({ episodeData: searchResult });
                            }
                        });
                }
                if (
                    formatHeaderNameWithOutSpace(data.name) ===
                    'episodevisitdata'
                ) {
                    // episodevisitRecordsetId = data.recordsetId;
                }
                const fieldIDArray = {};
                if (data.hasOwnProperty('fields') && data.fields.length > 0) {
                    data.fields.map((field, index) => {
                        fieldIDArray[
                            formatNameWithSpaceToUnderscore(field.name)
                        ] = field;
                    });
                    data.fields_obj = fieldIDArray;
                }
                const name = formatNameWithSpaceToUnderscore(data.name);
                allRecordSetID[name] = data;
                return data;
            });
        }
        this.setState({ allRecordSetID: allRecordSetID });
        await this.setState({
            patientModifiableRecordsetId: patientModifiableRecordsetId,
            patientFieldIdArray: patientFieldIdArray,
            patientRecordsetId: patientRecordsetId,
            modifyPatientFieldIdArray: modifyPatientFieldIdArray,
            modifyPatientRecordsetId: modifyPatientRecordsetId,
            modifyPatientModifiableRecordsetId:
                modifyPatientModifiableRecordsetId,
            orderFieldIdArray: orderFieldIdArray,
            orderRecordsetId: orderRecordsetId,
            orderModifiableRecordsetId: orderModifiableRecordsetId,
            modifyOrderFieldIdArray: modifyOrderFieldIdArray,
            modifyOrderRecordsetId: modifyOrderRecordsetId,
            modifyOrderModifiableRecordsetId: modifyOrderModifiableRecordsetId,
            physicianFieldIdArray: physicianFieldIdArray,
            physicianRecordsetId: physicianRecordsetId,
            physicianModifiableRecordsetId: physicianModifiableRecordsetId,
            edit_details: false,
        });
        if (this.props.ddl.hasOwnProperty('emrordertype')) {
            await this.setState({
                emr_order_typeDDL: this.props.ddl.emrordertype,
            });
        }
        // patient
        // let fieldData = GetFieldData({ configData: this.props.configData });
        // this.setState({ attachmentTypeDDL: this.props.ddl.attachmenttype });
        this.setState({ newReferralDDL: this.props.ddl.newreferral });

        if (this.props.editDetails === true) {
            this.setState({
                edit_details: true,
            });
        }

        // this.setState({editId:'/documents/edit/865'})

        const rulesJson = this.props.rulesJson;
        // eslint-disable-next-line guard-for-in
        for (let rules in rulesJson) {
            let rulesTemp = rules;
            rulesTemp = rulesTemp.replace('(', '');
            rulesTemp = rulesTemp.replace(')', '');
            const upperCaseWords = rulesTemp.match(
                /(\b[A-Z][A-Z]+|\b[A-Z]\b)/g
            );
            const firstWord = rulesTemp.split(' ')[0];
            const array = rulesJson[rules];
            rules = `${firstWord}-${upperCaseWords[0]}`;
            // var finalRules = {};
            // finalRules[rules]=array;
            this.state.rulesJson[rules] = array;
        }
        if (this.props.calingFrom === 'deleted_document') {
            await this.setState({
                editDetailsOutbox: this.props.editDetailsOutbox,
            });
        }

        if (this.props.reportView === 'report') {
            this.setState({
                allActions: {},
            });
        }
    }

    hideToast() {
        this.setState({
            showToastMsg: false,
        });
    }

    refreshViewAll = () => {
        this.setState({
            viewAllNotes: false,
        });
        this.setState({
            viewAllNotes: true,
        });
    };

    updateState = (state) => {
        this.setState({ ...state });
    };

    render() {
        const setPrinterloader = () => {
            this.setState({
                // printSelectedDocId:[],
                printLoader: false,
            });
        };
        // const selectedDocId = this.props.selectedDocId;
        const backBtn = () => {
            // var pathname = window.location.pathname.split('/');
            if (this.state.detailPage === true) {
                return (
                    <>
                        {' '}
                        <div className='back_btn'>
                            <button
                                onClick={() => this.props.goToPage()}
                                type='button'
                            >
                                Back
                            </button>
                        </div>{' '}
                    </>
                );
            }
            return null;
        };

        return (
            <>
                {this.state.isLocked === true &&
                String(this.props.location.pathname).includes('report') ===
                    true ? (
                    <div className='lock-message'>
                        <i className='fa fa-lock' aria-hidden='true' />
                        Document Locked. Someone else is working on this
                        document.
                    </div>
                ) : null}
                <div
                    className={
                        this.state.isLocked === true &&
                        String(this.props.location.pathname).includes(
                            'report'
                        ) === true
                            ? 'pdf-detail m-5 d-flex justify-content-end'
                            : 'pdf-detail mb-5 d-flex justify-content-end'
                    }
                >
                    <div
                        className={
                            this.state.isLocked === true
                                ? 'left-expand d-flex align-items-center justify-content-between right-function action-locked'
                                : 'left-expand d-flex align-items-center justify-content-between right-function'
                        }
                    >
                        <OutboxActionsComponent
                            {...this.state}
                            pdfPrintRef={this.props.pdfPrintRef}
                            selectedDocId={this.props.selectedDocId}
                            allActions={
                                this.state.allActions !== undefined
                                    ? this.state.allActions
                                    : {}
                            }
                            actionsDD={
                                this.props.calingFrom === 'deleted_document' &&
                                this.props.userPermission !== undefined &&
                                this.props.userPermission.allActions !==
                                    undefined
                                    ? this.props.userPermission.allActions
                                    : this.state.allActions
                            }
                            calingFrom='bigView'
                            editid_bigview={this.state.editId}
                            open_edit_details={this.state.open_edit_details}
                            isLocked={this.state.isLocked}
                            updateState={(state) => {
                                this.setState({ ...state });
                                this.props.updateState(state);
                            }}
                            outboxrefreshTable={() => {
                                this.outboxrefreshTable();
                            }}
                            openDetailView={() => {
                                this.props.openDetailView();
                            }}
                            deleteDocument={this.props.calingFrom}
                            deleted_page={this.props.deleted_page}
                            deletedPage={this.props.deletedPage}
                            delstatusId={this.props.delstatusId}
                            delprocessId={this.props.delprocessId}
                            //calingFromSeacrh={this.props.calingFrom}
                            globalActions={this.props.globalActions}
                            reportView={this.props.reportView}
                            rowTransition={() => {
                                this.props.rowTransition();
                            }}
                            closeBigView={() => {
                                this.props.closeBigView();
                            }}
                            ReportprocessId={this.props.ReportprocessId}
                            ReportstatusId={this.props.ReportstatusId}
                        />

                        {backBtn()}
                    </div>
                </div>
                <div
                    className={`toast-container ${this.state.toast_type}`}
                    style={{
                        position: 'absolute',
                        top: 0,
                        right: 0,
                        width: '25%',
                    }}
                >
                    {toastElement(
                        this.hideToast.bind(this),
                        this.state.showToastMsg,
                        this.state.toastHeaderText,
                        this.state.toastMessageText
                    )}
                </div>
                {/* Details Top menu Sections */}

                <div>
                    {this.state.refreshRD === false && (
                        <RelatedDocument
                            selectedDocId={this.props.selectedDocId}
                            isLocked={this.state.isLocked}
                            toggleSpinner={() => {}}
                            callingFrom={this.props.callingFrom}
                            propsCalingFrom={this.props.propsCalingFrom}
                            myParent='bigview'
                        />
                    )}

                    {this.state.printSelectedDocId.length > 0 ? (
                        <PrintPdfDocument
                            docId={this.state.printSelectedDocId}
                            loader={setPrinterloader}
                        />
                    ) : null}

                    {this.state.open_delivery_detail_section ? (
                        <DeliveryDetailModal
                            calingFrom='bigView'
                            isLocked={this.state.isLocked}
                            updateState={(state) => {
                                this.setState({ ...state });
                            }}
                            outboxrefreshTable={() => {
                                this.outboxrefreshTable();
                            }}
                            allActions={
                                this.props.calingFrom === 'deleted_document' &&
                                this.props.userPermission !== undefined &&
                                this.props.userPermission.allActions !==
                                    undefined
                                    ? this.props.userPermission.allActions
                                    : {}
                            }
                            deleteDocument={this.props.calingFrom}
                            delstatusId={this.props.delstatusId}
                            delprocessId={this.props.delprocessId}
                            selectedDocId={this.props.selectedDocId}
                            refreshForNextDocument={() => {
                                this.props.refreshForNextDocument();
                            }}
                            reportView={this.props.reportView}
                            rowTransition={() => {
                                this.props.rowTransition();
                            }}
                            lockedStatus={() => {
                                this.props.lockedStatus();
                            }}
                            closeBigView={() => {
                                this.props.closeBigView();
                            }}
                        />
                    ) : (
                        <div className='d-flex justify-content-center'>
                            <div
                                className='spinner-border text-primary'
                                role='status'
                            >
                                <span className='sr-only'>Loading...</span>
                            </div>
                        </div>
                    )}

                    <CoverPageModal
                        calingFrom='bigView'
                        isLocked={this.state.isLocked}
                        updateState={(state) => {
                            this.setState({ ...state });
                        }}
                        allActions={
                            this.props.calingFrom === 'deleted_document' &&
                            this.props.userPermission !== undefined &&
                            this.props.userPermission.allActions !== undefined
                                ? this.props.userPermission.allActions
                                : {}
                        }
                        deleteDocument={this.props.calingFrom}
                        delstatusId={this.props.delstatusId}
                        delprocessId={this.props.delprocessId}
                        selectedDocId={this.props.selectedDocId}
                        reportView={this.props.reportView}
                    />

                    <DetailSection
                        state={{ ...this.state }}
                        allActions={
                            this.props.calingFrom === 'deleted_document' &&
                            this.props.userPermission !== undefined &&
                            this.props.userPermission.allActions !== undefined
                                ? this.props.userPermission.allActions
                                : {}
                        }
                        open_edit_details={this.props.open_edit_details}
                        editDetailsOutbox={this.props.editDetailsOutbox}
                        isLocked={this.state.isLocked}
                        isDetailBigView={this.state.isDetailBigView}
                        selectedDocId={this.props.selectedDocId}
                        updateState={(state) => {
                            this.setState({ ...state });
                            if (typeof this.props.updateState === 'function') {
                                this.props.updateState();
                            }
                        }}
                        calingFrom='bigView'
                        deleteDocument={this.props.calingFrom}
                        delstatusId={this.props.delstatusId}
                        delprocessId={this.props.delprocessId}
                        reportView={this.props.reportView}
                    />

                    <div className='wrap_details wrap_note p-4 mt-6 notes-expand'>
                        <div className='d-flex mb-5 details_part align-items-stretch justify-content-between'>
                            <div className='details_fun'>
                                <span>
                                    <i
                                        title='Notes'
                                        className='fa-solid fa-sticky-note'
                                    >
                                        {' '}
                                    </i>
                                    Notes
                                </span>
                            </div>
                        </div>
                        <hr />

                        {this.state.viewAllNotes === true &&
                        this.props.selectedDocId !== undefined ? (
                            <ViewAllNotes
                                selectedDocId={this.props.selectedDocId}
                            />
                        ) : null}

                        <Notes
                            openNote={this.state.open_notes_section}
                            isLocked={this.state.isLocked}
                            docId={this.props.selectedDocId}
                            type='normal'
                            // handleReset={handleReset}
                            handleNotesSubmit={(
                                notes,
                                notesValue,
                                notesDDL,
                                docId
                            ) => {
                                this.set_handlenotessubmit(
                                    notes,
                                    notesValue,
                                    notesDDL,
                                    docId
                                );
                            }}
                            openBigView={() => {
                                this.openBigView();
                            }}
                            handleNotesText={this.props.handleNotesText}
                            handleNotesDDL={this.props.handleNotesDDL}
                            notesVal={this.props.notesVal}
                            notesSubmitted={this.props.notesSubmitted}
                            refreshViewAll={() => {
                                this.refreshViewAll();
                            }}
                            callingFrom={this.props.callingFrom}
                            ReportprocessId={this.props.ReportprocessId}
                            ReportstatusId={this.props.ReportprocessId}
                        />
                    </div>
                </div>
                {/* PDF preview Section */}
            </>
        );
    }
}

// export default DetailBigView;

const mapDispatchToProps = {
    getListaction: getListaction,
    setSelectedRow: setSelectedRow,
    getRulesJson: getRulesJson,
    showLoader: (data) => showLoader(data),
    hideBigView: (data) => hideBigView(data),
    getDDLlist: getDDLlist,
    getSelectedRowDetails,
    updateOutboxState,
};
const mapStateToProps = (state, ownProps) => {
    return {
        outbox: state.outbox,
        rowData: state.outbox.rowData,
        rulesJson: state.persist.rulesJson,
        configuredRecordsets:
            state.configReducerPersist.configData.configuredRecordsets,
        configData: state.configReducerPersist.configData,
        selectedRowDetails: state.outbox.selectedRowDetails,
        ddl: state.persistDDL.DDL,
        currentMenuName: state.persist.currentMenuName,
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(DetailBigView));
