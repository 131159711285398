/* eslint-disable array-callback-return */
import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Dropdown, Button } from 'react-bootstrap';
import { isMobile } from 'react-device-detect';
import { Notes } from '../../../components/notes/Notes';
import GlobalActions from './GlobalActions';
import { deletedPage } from '../../_redux/actions/searchActions';
import {
    formatNameWithSpaceToUnderscore,
    showErrorToast,
} from '../../../components/commonfunction';
import { TransitionComponent } from '../../inbox/components/TransitionComponent';
import { ViewAllNotes } from '../../inbox/components/ViewAllNotes';
import RelatedDocument from '../../inbox/components/RelatedDocument';
import { ViewDetails } from '../../inbox/components/ViewDetails';
import { DetailMoreInformation } from '../../inbox/components/DetailMoreInformation';
import { AddEditTag } from '../../../components/commonAction/AddEditTag';

class DeleteSmallView extends React.Component {
    _isMounted = false;

    constructor(props) {
        super(props);
        this.state = {
            deleteBigView: false,
            relatedDoc: '',
            selectedDocId: 0,
            noteCount: this.props?.selectedRowDetails?.miscPproperties
                ?.noteCount
                ? this.props.selectedRowDetails.miscPproperties.noteCount
                : 0,
            noUpdate: false,
            viewNotes: false,
        };
    }

    async componentDidMount() {
        this._isMounted = true;
    }

    componentDidUpdate(prevProps, prevState) {
        if (
            Object.keys(this.props.selectedRowDetails).length !== 0 &&
            this.props?.selectedRowDetails?.miscPproperties?.noteCount !==
                this.state.noteCount &&
            this.state.noUpdate !== true
        ) {
            this.setState({
                noteCount:
                    this.props.selectedRowDetails.miscPproperties.noteCount,
            });
        }
        if (prevState.noteCount !== this.state.noteCount) {
            if (this.state.noteCount !== 0) {
                this.setState({
                    viewNotes: false,
                });
                setTimeout(() => {
                    this.setState({
                        viewNotes: true,
                    });
                }, 100);
            } else {
                this.setState({
                    viewNotes: false,
                });
            }
        }
        if (
            this.props.deleted_page !== undefined &&
            this.props.deleted_page.selectedDocId !== undefined &&
            Number(this.state.selectedDocId) !==
                Number(this.props.deleted_page.selectedDocId)
        ) {
            this.setState({
                relatedDoc: false,
                selectedDocId: this.props.deleted_page.selectedDocId,
            });
            setTimeout(() => {
                this.setState({
                    relatedDoc: true,
                });
            }, 1000);
        }
    }

    async componentWillUnmount() {
        await this.props.deletedPage({ row_transition: false });
        this._isMounted = false;
    }

    updateState = (state) => {
        this.props.updateState(state);
        this.setState({ ...state });
    };

    open_notes_section = () => {
        this.setState({
            open_notes_section: !this.state.open_notes_section,
            open_transition_section: false,
            open_details_section: false,
        });
    };

    rowTransition = async () => {
        this.setState({
            row_transition: false,
            detailBigView: false,
        });
    };

    doCollapse = async () => {
        document.body.classList.remove('aside-minimize');
        document.body.classList.remove('doc-small-view');
        const elements = document.querySelectorAll('.rdg-row');
        // eslint-disable-next-line no-empty
        for (let i = 0; i < elements.length; i++) {}
        // this.setState({ row_transition: false });
        // await this.rowTransition();
        this.props.deletedPage({ row_transition: false });
        if (isMobile) {
            document.body.classList.remove('small-view-display');
            this.props.updateState({
                check: true,
            });
        }
        setTimeout(() => {
            const checkBox =
                document.getElementsByClassName('rdg-checkbox-input');
            if (checkBox !== null) {
                for (let i = 0; i < checkBox.length; i++) {
                    checkBox[i].disabled = false;
                    checkBox[i].removeAttribute('disabled');
                }
            }
        }, 20);
    };

    doExpand = () => {
        // This below line commented since we using by document type so hide this one - Aiswarya
        /*  if (this.props.selectedRowDetails.statuses.length > 0) {
            if (this.props.selectedRowDetails.statuses[0].processId === 1) {
                this.props.deletedPage({ currentBigView: 'inbox' });
            }
            if (this.props.selectedRowDetails.statuses[0].processId === 3) {
                this.props.deletedPage({ currentBigView: 'outbox' });
            } else {
                this.props.deletedPage({ currentBigView: 'inbox' });
            }
        } */
        // need to check
        document.body.classList.remove('doc-small-view');
        document.body.classList.add('doc-detail-view');
        // document.body.classList.add('expand-view');
        // if (
        //     document.getElementById('pdf-scroll') &&
        //     document.getElementById('pdf-scroll') !== undefined
        // ) {
        //     document.getElementById('pdf-scroll').style.height = '100%';
        // }
        const checkBox = document.getElementsByClassName('rdg-checkbox-input');
        if (checkBox !== null) {
            for (let i = 0; i < checkBox.length; i++) {
                checkBox[i].disabled = true;
            }
        }
        this.props.openBigView();
        this.setState({
            deleteBigView: true,
            listingView: false,
            open_notes_section: false,
        });
        if (isMobile) {
            document.body.classList.remove('small-view-display');
            this.props.updateState({
                mobileHide: true,
                mobileTab: true,
            });
        }
    };

    collapseBigView = () => {
        this.props.deletedPage({ collapseBigView: true });
        this.setState({ deleteBigView: false });
        document.body.classList.add('doc-small-view');
        this.props.closeBigView();
        this.props.updateState({
            mobileHide: false,
            mobileTab: false,
        });
        this.props.updateState({
            edit_details: false,
        });
    };

    closeTransition = () => {
        this.doCollapse();
    };

    open_transition_section = async () => {
        let editDetailPage = {};
        this.setState({
            open_transition_section: !this.state.open_transition_section,
            open_details_section: false,
            open_notes_section: false,
        });

        this.props.selectedRowDetails.fields.map((data) => {
            this.props.allFields.map((value) => {
                const name = formatNameWithSpaceToUnderscore(value.name);
                if (
                    (value.name === 'Branch' ||
                        value.name === 'Workflow Status' ||
                        value.name === 'Group') &&
                    value.fieldId === data.fieldId
                ) {
                    editDetailPage = {
                        ...editDetailPage,
                        [name]: {
                            fieldId: value.fieldId,
                            value: data.value,
                            fieldName: value.name,
                        },
                    };
                    if (value.name === 'Branch') {
                        this.setState({
                            menuBranch: data.value,
                        });
                    }
                    if (value.name === 'Group') {
                        this.setState({
                            menuGroup: data.value,
                        });
                    }
                    if (value.name === 'Workflow Status') {
                        this.setState({
                            menuInbox: data.value,
                        });
                    }
                }
            });
        });

        await this.setState({
            editDetailPage: { selectedRowDetail: editDetailPage },
        });
    };

    open_details_section() {
        this.setState({
            open_transition_section: false,
            open_details_section: !this.state.open_details_section,
            open_notes_section: false,
        });
    }

    toggleSpinner = () => {
        this.setState({
            docSpinner: !this.state.docSpinner,
        });
        this.props.enableLoader(!this.state.docSpinner);
    };

    openMore = () => {
        this.setState({ more_information: !this.state.more_information });
    };

    edit_details = () => {
        this.doExpand();
        this.props.updateState({
            edit_details: true,
        });
    };

    render() {
        const handleReset = () => {
            this.setState({
                openNote: false,
            });
        };

        return (
            <>
                {this.props.miscPproperties !== undefined &&
                this.props.miscPproperties.isLocked === true ? (
                    <div className='lock-message'>
                        <i className='fa fa-lock' aria-hidden='true' />
                        Document Locked. Someone else is working on this
                        document.
                    </div>
                ) : null}
                <div>
                    <div
                        className={
                            this.props.miscPproperties !== undefined &&
                            this.props.miscPproperties.isLocked === true
                                ? 'pdf-detail d-flex align-items-stretch justify-content-between locked-screen'
                                : 'pdf-detail d-flex align-items-stretch justify-content-between'
                        }
                    >
                        <div className='left-expand left-ex-btn'>
                            {this.state.deleteBigView === false ? (
                                <>
                                    <div
                                        className='ml-0 left-pdf-button'
                                        onClick={this.doExpand}
                                    >
                                        <span title='Expand'>
                                            <i className='fas fa-arrow-to-left' />
                                            Expand
                                        </span>
                                    </div>
                                    <div
                                        className='left-pdf-button'
                                        onClick={this.doCollapse}
                                    >
                                        <span title='Collapse'>
                                            <i className='fas fa-arrow-to-right' />
                                            Collapse
                                        </span>
                                    </div>
                                </>
                            ) : null}

                            {this.state.deleteBigView === true ? (
                                <div
                                    className='left-pdf-button'
                                    onClick={this.collapseBigView}
                                >
                                    <span title='Collapse'>
                                        <i className='fas fa-arrow-to-right' />
                                        Collapse
                                    </span>
                                </div>
                            ) : null}
                            {this.props.location.pathname.includes(
                                'websearch-inbound-documents'
                            ) !== true &&
                            this.props.location.pathname.includes(
                                'websearch-outbound-documents'
                            ) !== true &&
                            this.props.location.pathname.includes('search') !==
                                true ? (
                                <>
                                    {this.props.miscPproperties !== undefined &&
                                    this.props.miscPproperties.isLocked ===
                                        true &&
                                    this.state.deleteBigView === true ? (
                                        <span
                                            style={{ pointerEvents: 'none' }}
                                            className={
                                                this.state.tagCount > 0
                                                    ? 'details-tag contain-tag-value ml-3'
                                                    : 'details-tag ml-3'
                                            }
                                            id='details-tag'
                                            onClick={() => {
                                                showErrorToast('Docs Locked');
                                            }}
                                            disabled
                                        >
                                            <span className='fatag active'>
                                                <i className='fas fa-tag' />
                                            </span>
                                            <span className='count'>
                                                {this.state.tagCount}
                                            </span>
                                        </span>
                                    ) : this.state.deleteBigView === true &&
                                      this.props.deleted_page.allActions !==
                                          undefined &&
                                      this.props.deleted_page.allActions
                                          .update_tag !== undefined ? (
                                        <Dropdown className='tag_dropdown '>
                                            <Dropdown.Toggle className='dropdown_btn'>
                                                {this.props.detailView ===
                                                undefined ? (
                                                    <span
                                                        className={
                                                            this.state
                                                                .tagCount > 0
                                                                ? 'details-tag contain-tag-value'
                                                                : 'details-tag'
                                                        }
                                                        // title={this.state.tagData.toString()}
                                                        id='details-tag'
                                                        // onClick={() => {
                                                        //     this.handleTagOpen();
                                                        // }}
                                                    >
                                                        <span
                                                            className={
                                                                this.state
                                                                    .tagCount >
                                                                0
                                                                    ? 'fatag active'
                                                                    : 'fatag'
                                                            }
                                                        >
                                                            <i
                                                                className='fas fa-tag'

                                                                // style={{
                                                                //     color:
                                                                //         this.state.allActions.hasOwnProperty(
                                                                //             'update_tag'
                                                                //         ) &&
                                                                //         this.state
                                                                //             .allActions
                                                                //             .update_tag
                                                                //             .color !==
                                                                //             null
                                                                //             ? this.state
                                                                //                   .allActions
                                                                //                   .update_tag
                                                                //                   .color
                                                                //             : '',
                                                                // }}
                                                            />
                                                        </span>
                                                        <span className='count'>
                                                            {
                                                                this.state
                                                                    .tagCount
                                                            }
                                                        </span>
                                                    </span>
                                                ) : null}
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu>
                                                <div>
                                                    <AddEditTag
                                                        selectedTag={
                                                            this.props
                                                                .menuSelectedTags
                                                        }
                                                        tagList={
                                                            this.props
                                                                .configData
                                                                .tags !==
                                                            undefined
                                                                ? this.props
                                                                      .configData
                                                                      .tags
                                                                : null
                                                        }
                                                        tagColorArray={
                                                            this.props
                                                                .tagColorArray
                                                        }
                                                        selectedTagNameData={
                                                            this.state.tagData
                                                        }
                                                        docId={
                                                            this.props
                                                                .deleted_page
                                                                .selectedDocId
                                                        }
                                                        // resetCount={resetCount}
                                                        updateTag={(tag) => {
                                                            this.setState({
                                                                tagData: tag,
                                                            });
                                                        }}
                                                        handleTagClose={
                                                            this.handleTagClose
                                                        }
                                                        refreshTag={
                                                            this.props
                                                                .refreshTag
                                                        }
                                                        allFields={
                                                            this.props
                                                                .configData
                                                                .fields
                                                        }
                                                    />
                                                </div>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    ) : null}
                                </>
                            ) : null}
                        </div>

                        <div
                            className={
                                this.props.miscPproperties !== undefined &&
                                this.props.miscPproperties.isLocked === true
                                    ? 'right-function action-locked'
                                    : 'right-function'
                            }
                            style={{ display: 'flex' }}
                        >
                            <GlobalActions
                                rowTransition={this.rowTransition}
                                selectedDocId={
                                    this.props.deleted_page.selectedDocId
                                }
                                openNotes={this.open_notes_section}
                                callingFrom='smallView'
                                deleteBigView={this.state.deleteBigView}
                                collapseBigView={this.collapseBigView}
                                updateState={this.updateState}
                                isLocked={
                                    this.props.miscPproperties !== undefined
                                        ? this.props.miscPproperties.isLocked
                                        : false
                                }
                                open_transition_section_function={() => {
                                    this.open_transition_section();
                                }}
                                open_transition_section={
                                    this.state.open_transition_section
                                }
                                open_notes_section={
                                    this.state.open_notes_section
                                }
                                open_details_section_function={() => {
                                    this.open_details_section();
                                }}
                                open_details_section={
                                    this.state.open_details_section
                                }
                                refreshForNextDocument={() => {
                                    this.props.refreshForNextDocument();
                                }}
                            />
                        </div>
                    </div>
                    {this.state.open_transition_section &&
                    this.state.deleteBigView !== true &&
                    this.props.deleted_page.allActions !== undefined ? (
                        <div className='wrap_transition p-4'>
                            <TransitionComponent
                                content='normal'
                                menu_name={this.props.menu_name}
                                processId={this.props.deleted_page.processId}
                                statusId={this.props.deleted_page.statusId}
                                statusArray={
                                    this.props.deleted_page.permission
                                        .statusArray
                                }
                                branches={this.props.branches}
                                groups={this.props.groups}
                                selectedDocId={
                                    this.props.deleted_page.selectedDocId
                                }
                                editTransition={false}
                                // branchActionId={
                                //     this.state.deleted_page.branchActionId
                                // }
                                groupActionId={this.state.groupActionId}
                                showBranch={
                                    this.props.deleted_page.permission
                                        .show_branch
                                }
                                showGroup={
                                    this.props.deleted_page.permission
                                        .show_group
                                }
                                showInbox={
                                    this.props.deleted_page.permission
                                        .show_inbox
                                }
                                closeTransition={(param) => {
                                    this.closeTransition.call(this);
                                }}
                                isLocked={
                                    this.props.selectedRowDetails
                                        .miscPproperties.isLocked
                                }
                                menuGroup={this.state.menuGroup}
                                menuBranch={this.state.menuBranch}
                                menuInbox={this.state.menuInbox}
                                calingFrom='deleted_document'
                                subCalingFrom='global_actions'
                                updateState={this.updateState}
                                editDetailPage={this.state.editDetailPage}
                                ddl={this.props.ddl}
                                configData={this.props.configData}
                                // closeTransition={()=>{
                                //     this.closeTransition();
                                // }}
                            />
                        </div>
                    ) : null}

                    {this.state.open_notes_section ? (
                        <div className='wrap_details wrap_note p-4'>
                            <div
                                className={
                                    this.state.noteCount === 0
                                        ? 'd-flex details_part align-items-stretch justify-content-between'
                                        : 'd-flex mb-5 details_part align-items-stretch justify-content-between'
                                }
                            >
                                <div className='details_fun'>
                                    <span>
                                        <i
                                            title='Notes'
                                            className='fa-solid fa-sticky-note'
                                        >
                                            {' '}
                                        </i>
                                        Notes
                                    </span>
                                </div>
                                {/* this.state.noteCount > 0 ? (
                                    <div
                                        className='edit_details'
                                        onClick={() => goToFullScreen()}
                                        // not defined morining error
                                    >
                                        <span>
                                            View all notes
                                            <i
                                                className='fa fa-angle-right'
                                                aria-hidden='true'
                                            />
                                        </span>
                                    </div>
                                ) : null */}
                            </div>
                            {this.state.noteCount === 0 && (
                                <div className='no_result norecordfound mt-0 mb-3'>
                                    <span>No Notes Found </span>
                                </div>
                            )}
                            {this.state.viewNotes === true ? (
                                <ViewAllNotes
                                    selectedDocId={
                                        this.props.deleted_page.selectedDocId
                                    }
                                />
                            ) : null}
                            <hr />

                            <Notes
                                openNote={this.state.open_notes_section}
                                docId={this.props.deleted_page.selectedDocId}
                                type='normal'
                                updateState={() => {
                                    let countNotes = this.state.noteCount + 1;
                                    this.setState({
                                        noteCount: countNotes,
                                        noUpdate: true,
                                    });
                                }}
                                handleReset={handleReset}
                                viewAllOpen
                                statuses={
                                    this.props.statuses !== undefined
                                        ? this.props.statuses
                                        : []
                                }
                            />
                        </div>
                    ) : null}
                    {this.state.open_details_section &&
                    this.state.deleteBigView !== true &&
                    this.props.deleted_page.allActions !== undefined ? (
                        <div className='wrap_details p-4'>
                            <div className='d-flex details_part mb-3 align-items-stretch justify-content-between'>
                                <div className='details_fun'>
                                    <span>
                                        <i
                                            title='Details'
                                            className='fa-solid fa-notes-medical'
                                        >
                                            {' '}
                                        </i>
                                        Details
                                    </span>
                                </div>
                                {this.props.deleted_page.allActions !==
                                undefined ? (
                                    <div className='edit_details'>
                                        <Button
                                            variant='primary'
                                            title='Edit Details'
                                            disabled={
                                                this.props.isLocked === true
                                            }
                                            onClick={this.edit_details}
                                        >
                                            Edit Details
                                        </Button>
                                    </div>
                                ) : null}
                            </div>
                            <ViewDetails
                                configData={this.props.configData}
                                ddl={this.props.ddl}
                                configuredRecordsets={
                                    this.props.configData.configuredRecordsets
                                }
                                selectedRowDetails={
                                    this.props.selectedRowDetails
                                }
                                subCalingFrom='global_actions'
                            />
                            {/* <div className="text_detail mb-2"> <span>No details added yet. Click on the "Edit Details" button above to get started.</span></div> */}
                            <div className='more_info' onClick={this.openMore}>
                                <a href='#ngo' className='mt-4 mb-4'>
                                    {this.state.more_information ? (
                                        <div>
                                            <i className='fa-regular fa-chevron-up' />
                                            <button type='button'>
                                                {' '}
                                                Less Information
                                            </button>
                                        </div>
                                    ) : (
                                        <div>
                                            <i className='fa-regular fa-chevron-down' />
                                            <button type='button'>
                                                {' '}
                                                More Information
                                            </button>
                                        </div>
                                    )}
                                </a>
                            </div>
                            {this.state.more_information ? (
                                <DetailMoreInformation
                                    configData={this.props.configData}
                                    ddl={this.props.ddl}
                                    configuredRecordsets={
                                        this.props.configData
                                            .configuredRecordsets
                                    }
                                    selectedRowDetails={
                                        this.props.selectedRowDetails
                                    }
                                    subCalingFrom='global_actions'
                                />
                            ) : null}
                        </div>
                    ) : null}
                    {this.state.deleteBigView === false &&
                    this.props.deleted_page.selectedDocId !== undefined &&
                    this.state.relatedDoc ? (
                        <RelatedDocument
                            selectedDocId={
                                this.props.deleted_page.selectedDocId
                            }
                            isLocked={this.props.selectedRowDetails.isLocked}
                            toggleSpinner={this.toggleSpinner}
                            calingFrom='Search'
                            allActions={this.props.deleted_page.allActions}
                        />
                    ) : null}
                </div>
                {/* <PdfPreview docId={this.props.deleted_page.selectedDocId} /> */}
            </>
        );
    }
}

const mapDispatchToProps = { deletedPage };
const mapStateToProps = (state) => {
    return {
        deleted_page: state.search.deletedPage,
        selectedRowDetails: state.search.selectedRowDetails,
        miscPproperties: state.search.selectedRowDetails.miscPproperties,
        statuses: state.search.selectedRowDetails.statuses,
        configData: state.configReducerPersist.configData,
        allFields: state.configReducerPersist.configData.fields,
        ddl: state.persistDDL.DDL,
    };
};
export default React.memo(
    connect(mapStateToProps, mapDispatchToProps)(withRouter(DeleteSmallView))
);
