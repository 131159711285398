import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Modal } from 'react-bootstrap';
import DeletedDocumentsDataGrid from '../../components/datagrid/DeletedDocumentsDataGrid';
import DeleteSmallView from './components/DeleteSmallView';
import { SpinnerThree, SpinnerBlur } from '../../components/Spinner';
import { DocumentHistroy } from '../../components/documentHistory/DocumentHistroy';
import { getDDLlist } from '../_redux/actions/configActions';
import {
    deletedPage,
    updateSearchState,
    selectedRowDetail,
} from '../_redux/actions/searchActions';
import EditWorkPage from '../EditWorkPage';
import PdfPreview from '../../components/pdfpreview/PdfPreview';
import DetailBigView from '../inbox/components/DetailBigView';
// eslint-disable-next-line import/no-named-default
import { default as OutboxBigView } from '../outbox/components/DetailBigView';
// eslint-disable-next-line import/no-named-default
import { default as OutboxSmallView } from '../outbox/components/OutboxSmallView';
import {
    GetFieldData,
    download,
    userPermission,
    stringEmptyOrUndefined,
    formatHeaderNameWithOutSpace,
    getDropDownDataFromApi,
    getPageFromPath,
} from '../../components/commonfunction';
import { getConfig } from '../../../_metronic/_helpers';

class DeletedDocumentPage extends React.Component {
    _isMounted = false;

    constructor(props) {
        super(props);
        this.state = {
            filter_options: {},
            deleteBigView: false,
            paginationRefresh: true,
            edit_details: false, // Inbox edit details
            editDetailsOutbox: false,
            pathName: '',
        };
        this.closeBigView = this.closeBigView.bind(this);
    }

    closeBigView = () => {
        document.body.classList.remove('doc-detail-view');
        this.setState({
            deleteBigView: false,
        });
    };

    refreshForNextDocument = async (docid) => {
        document.body.classList.remove('aside-minimize');
        // await this.props.updateState({
        //     show_result: false,
        // });
        this.props.updateSearchState({
            deletedPage: {
                ...this.props.search.deletedPage,
                row_transition: false,
            },
        });
        this.closeBigView();
        // setTimeout(() => {
        //     this.props.updateState({
        //         show_result: true,
        //     });
        // }, 1000);
    };

    getBranchDropDownData = () => {
        const fieldData = GetFieldData({ configData: this.props.configData });

        if (stringEmptyOrUndefined(fieldData)) {
            if (!this.props.ddl.hasOwnProperty('attachmenttype')) {
                getDropDownDataFromApi(fieldData.attachmenttype).then(
                    (data) => {
                        if (data.values) {
                            this.props.getDDLlist({
                                [formatHeaderNameWithOutSpace(data.name)]:
                                    data.values,
                            });
                        }
                    }
                );
            }
        }
    };

    componentDidMount() {
        const pathnames = this.props.location.pathname;
        const pathname = getPageFromPath(pathnames);
        // eslint-disable-next-line array-callback-return
        this.props.customSearchQueries.map((data) => {
            if (String(data.searchId) === String(pathname)) {
                this.setState({
                    globalActions: data.globalActions,
                });
            }
        });
        this.getBranchDropDownData();
        const config = getConfig();
        const statusArray = [];

        if (config.length > 0 && config[0].fields !== undefined) {
            if (config[0].menuItems.length > 0) {
                config[0].menuItems.forEach((data) => {
                    if (
                        data.menuName === 'Inbox' &&
                        data.processes.length > 0
                    ) {
                        data.processes.forEach((value) => {
                            if (value.statuses.length > 0) {
                                value.statuses.forEach((val) => {
                                    if (value.processName === 'Inbound') {
                                        statusArray.push({
                                            id: val.statusId,
                                            name: val.name,
                                        });
                                    }
                                });
                            }
                        });
                    }
                });
            }
        }

        this.setState({
            statusArray: statusArray,
        });

        document.body.classList.remove('aside-minimize');
        document.body.classList.remove('doc-small-view');
        this.props.updateState({
            operate_search: false,
            operate_filter: {},
        });
        this.setState({
            filter_options: this.props.operate_filter,
        });
    }

    componentWillUnmount() {
        this.props.updateSearchState({
            deletedPage: {
                ...this.props.search.deletedPage,
                row_transition: false,
            },
        });
    }

    async componentDidUpdate(prevProps, prevState) {
        if (prevProps.operate_search !== this.props.operate_search) {
            if (this.props.operate_search) {
                this.setState({
                    filter_options: this.props.operate_filter,
                });
            }
        }
        // added for custome-search-queries on click hide the detail big view
        if (document.body.classList.contains('doc-detail-view')) {
            const customClick = document.getElementsByClassName(
                'custome-search-queries'
            );
            if (customClick[0] !== undefined) {
                customClick[0].onclick = function () {
                    this.closeBigView();
                }.bind(this);
            }
        }
        // added for search-button on click hide the detail big view
        if (document.body.classList.contains('doc-detail-view')) {
            const customClick =
                document.getElementsByClassName('search-button');
            if (customClick[0] !== undefined) {
                customClick[0].onclick = function () {
                    this.closeBigView();
                    this.props.updateSearchState({
                        deletedPage: {
                            ...this.props.search.deletedPage,
                            row_transition: false,
                        },
                    });
                    document.body.classList.remove('aside-minimize');
                }.bind(this);
            }
        }

        if (
            this.props.location.pathname === '/search/patients' &&
            this.props.location.pathname !== this.state.pathName
        ) {
            //if(this.props.deleted_page.backBtn !== true)
            //{

            await this.setState({
                single_edit: false,
                pathName: this.props.location.pathname,
                row_transition: false,
                deleteBigView: false,
            });
            await this.props.deletedPage({
                related_doc_detail: false,
                multiple_edit_page: false,
                single_edit: false,
                row_transition: false,
            });
            await this.props.updateState({
                dataGrid: false,
                show_result: true,
            });
            setTimeout(() => {
                this.props.updateState({
                    dataGrid: true,
                    show_result: true,
                });
            }, 500);

            // }else{
            /*  
                this.props.deletedPage({
                    backBtn:false,
                })
                return false */
            // }
        }
        if (this.props.location.pathname !== this.state.pathName) {
            this.setState({
                pathName: this.props.location.pathname,
            });
        }
    }

    closeHistory = () => {
        this.props.deletedPage({ openHistory: false });
    };

    openBigView = () => {
        if (this.props.selectedRowDetails.statuses.length > 0) {
            const permission = userPermission({
                processId: this.props.selectedRowDetails.statuses[0].processId,
                statusId: this.props.selectedRowDetails.statuses[0].statusId,
                menu_name: 'Search',
                configData: this.props.configData,
            });

            this.setState({
                userPermission: permission,
                processId: this.props.selectedRowDetails.statuses[0].processId,
                statusId: this.props.selectedRowDetails.statuses[0].statusId,
            });
        } else {
            this.setState({
                userPermission: undefined,
                processId: undefined,
                statusId: undefined,
            });
        }

        const fieldDataObj = GetFieldData({
            configData: this.props.configData,
        });
        const data = this.props.selectedRowDetails;
        if (data.fields !== undefined) {
            data.fields.forEach((dataValue) => {
                const fields = dataValue.fieldId;
                if (fields === fieldDataObj.branchFieldId) {
                    this.setState({
                        menuBranch: dataValue.value,
                    });
                }
                if (fields === fieldDataObj.groupFieldId) {
                    this.setState({
                        menuGroup: dataValue.value,
                    });
                }
                if (fields === fieldDataObj.workflow_status) {
                    this.setState({
                        menu_inbox: dataValue.value,
                    });
                }
                // for (const property in configData) {
                //     if (configData[property].fieldId === fields) {
                //         foundFields.push(configData[property]);
                //     }
                // }
            });
        }

        this.setState({
            isLockedBy: data.miscPproperties.isLockedBy,
            isLocked: data.miscPproperties.isLocked,
            noteCount: data.miscPproperties.noteCount,
            pageCount: data.miscPproperties.pageCount,
        });
        this.setState({
            deleteBigView: true,
        });
    };

    updateState = (state) => {
        this.setState({ ...state });
        this.props.updateState({ ...state });
    };

    enableLoader(value) {
        this.setState({ blur_spinner: value });
    }

    render() {
        return (
            <>
                <SpinnerBlur show={this.props.deleted_page.blurSpinner} />

                <div
                    style={
                        this.props.deleted_page.single_edit === true
                            ? { display: 'none' }
                            : {}
                    }
                >
                    {this.props.show_result ? (
                        <DeletedDocumentsDataGrid
                            calingFrom='search'
                            pageTitle={this.props.pageTitle}
                            filter_options={this.state.filter_options}
                            deleteBigView={this.state.deleteBigView}
                            operate_search={this.props.operate_search}
                            paginationRefresh={this.state.paginationRefresh}
                            query={this.props.query}
                            patientId={this.props.patientId}
                            tab_name={this.props.tab_name}
                            editDetailsOutbox={this.state.editDetailsOutbox}
                            updateState={(state) => {
                                this.setState({ ...state });
                                this.props.updateState({ ...state });
                            }}
                            edit_details={this.state.edit_details}
                        />
                    ) : null}

                    {/*
                        <div className='no_result norecordfound search_error'>
                            <span> Operate search to view search results </span>
					</div> */}

                    {this.props.deleted_page.row_transition === true ? (
                        <div
                            id='left-aside'
                            className={
                                this.props.miscPproperties !== undefined &&
                                this.props.miscPproperties.isLocked === false
                                    ? 'left-aside py-5 rounded-lg aside-fixed flex-row-auto no-lock-section 123'
                                    : 'left-aside py-9 rounded-lg aside-fixed flex-row-auto'
                            }
                            style={
                                this.state.deleteBigView === true
                                    ? { margin: 0 }
                                    : {}
                            }
                        >
                            {this.props.deleted_page.currentBigViewSearch ===
                            'inbox' ? (
                                // ||this.props.deleted_page.currentBigView === 'outbox'
                                <DeleteSmallView
                                    openBigView={this.openBigView}
                                    closeBigView={this.closeBigView}
                                    updateState={this.updateState}
                                    enableLoader={this.enableLoader.bind(this)}
                                    refreshForNextDocument={() => {
                                        this.refreshForNextDocument();
                                    }}
                                    calingFrom='deleted_document'
                                    mobileHide={this.state.mobileHide}
                                    mobileTab={this.props.mobileTab}
                                    edit_details={this.state.edit_details}
                                />
                            ) : this.props.deleted_page.currentBigViewSearch ===
                              'outbox' ? (
                                <OutboxSmallView
                                    nextDocument={this.state.nextDocument}
                                    resetNextDoc={() => {
                                        this.setState({ nextDocument: false });
                                    }}
                                    notesVal={this.state.notesVal}
                                    openDeletePopUp={() => {
                                        this.openDeletePopUp.call(this);
                                    }}
                                    // rowTransition={() => {
                                    //     this.rowTransition();
                                    // }}
                                    selectedGroup={this.set_group}
                                    openBigView={() => {
                                        this.openBigView();
                                    }}
                                    openDetailView={() => {
                                        this.openDetailView();
                                    }}
                                    notesDDL={JSON.parse(
                                        localStorage.getItem('noteTypes')
                                    )}
                                    isLocked={this.state.is_locked}
                                    lockedBy={this.state.is_locked_by}
                                    closeBigView={() => {
                                        this.closeBigView();
                                    }}
                                    rowData={this.state.rowData}
                                    rowDetails={this.state.rowDetails}
                                    ownerlist={this.state.owners}
                                    menuTags={this.state.menu_tags}
                                    menuSelectedTags={
                                        this.state.menu_selected_tag
                                    }
                                    tagColorArray={this.state.newTagColorArray}
                                    editDetails={this.editDetails}
                                    showDelete={this.state.show_delete}
                                    // refreshTag={() => {
                                    //     this.refreshTag();
                                    // }}
                                    enableLoader={this.enableLoader.bind(this)}
                                    menu_name={this.state.menu_name}
                                    allActions={
                                        this.props.deleted_page.allActions
                                    }
                                    updateState={(state) => {
                                        this.setState({ ...state });
                                        this.props.updateState({ ...state });
                                    }}
                                    refreshTable={() => {
                                        this.refreshTable();
                                    }}
                                    pdf_preview={this.state.pdf_preview}
                                    open_edit_details={
                                        this.state.open_edit_details
                                    }
                                    calingFrom='deleted_document'
                                    statuses={
                                        this.props.statuses !== undefined
                                            ? this.props.statuses
                                            : []
                                    }
                                    delstatusId={
                                        this.props.deleted_page.statusId !==
                                        undefined
                                            ? this.props.deleted_page.statusId
                                            : ''
                                    }
                                    delprocessId={
                                        this.props.deleted_page.processId !==
                                        undefined
                                            ? this.props.deleted_page.processId
                                            : ''
                                    }
                                    refreshForNextDocument={() => {
                                        this.refreshForNextDocument();
                                    }}
                                    selectedDocId={
                                        this.props.deleted_page.selectedDocId
                                    }
                                    deletedPage={this.props.deletedPage}
                                    editDetailsOutbox={
                                        this.state.editDetailsOutbox
                                    }
                                    globalActions={this.state.globalActions}
                                    mobileHide={this.state.mobileHide}
                                    mobileTab={this.props.mobileTab}
                                    is_lockedSearch={
                                        this.props.deleted_page
                                            .selectedRowDetails.isLocked
                                    }
                                />
                            ) : null}
                            {this.props.deleted_page.single_edit === false && (
                                <PdfPreview
                                    docId={
                                        this.props.deleted_page.selectedDocId
                                    }
                                />
                            )}
                        </div>
                    ) : this.props.deleted_page.row_transition === '' ? (
                        <div
                            id='left-aside'
                            className={
                                this.state.deleteBigView
                                    ? 'left-aside py-5 rounded-lg  aside-fixed flex-row-auto aside-small-view'
                                    : 'left-aside py-9 rounded-lg aside-fixed flex-row-auto'
                            }
                            style={{
                                height:
                                    window.innerHeight - window.innerHeight / 7,
                            }}
                        >
                            <SpinnerThree show />
                        </div>
                    ) : null}

                    {this.state.deleteBigView === true ? (
                        <div
                            id='left-last-aside'
                            className={
                                this.state.deleteBigView
                                    ? 'expand-mode left-aside py-5 rounded-lg aside-fixed flex-row-auto'
                                    : null
                            }
                        >
                            {this.props.deleted_page.currentBigViewSearch ===
                            'inbox' ? (
                                <DetailBigView
                                    selectedDocId={
                                        this.props.deleted_page.selectedDocId
                                    }
                                    download={() => {
                                        download(
                                            this.props.deleted_page
                                                .selectedDocId
                                        );
                                    }}
                                    openDeletePopUp={() => {}}
                                    statusArray={
                                        this.state.userPermission !==
                                            undefined &&
                                        this.state.userPermission
                                            .statusArray !== undefined
                                            ? this.state.userPermission
                                                  .statusArray
                                            : this.state.statusArray
                                    }
                                    branches={this.state.branches}
                                    groups={this.state.groups}
                                    rowTransition={() => {
                                        this.rowTransition();
                                    }}
                                    menuGroup={this.state.menuGroup}
                                    menuBranch={this.state.menuBranch}
                                    menuInbox={this.state.menu_inbox}
                                    showBranch
                                    showDelete={this.state.show_delete}
                                    showDownload={this.state.show_download}
                                    showGroup
                                    showInbox
                                    showNotes
                                    showOwner={this.state.show_owner}
                                    showPrint={this.state.show_print}
                                    showPdf={this.state.show_pdf}
                                    goToPage={this.goToPage}
                                    calingFrom='deleted_document'
                                    more_information
                                    updateState={(state) => {
                                        this.setState({ ...state });
                                        this.props.updateState({ ...state });
                                    }}
                                    deleted_page={this.props.deleted_page}
                                    deletedPage={this.props.deletedPage}
                                    isLocked={
                                        this.props.miscPproperties !== undefined
                                            ? this.props.miscPproperties
                                                  .isLocked
                                            : false
                                    }
                                    userPermission={this.state.userPermission}
                                    statuses={
                                        this.props.statuses !== undefined
                                            ? this.props.statuses
                                            : []
                                    }
                                    refreshForNextDocument={(id) => {
                                        this.refreshForNextDocument(id);
                                    }}
                                    statusId={this.state.statusId}
                                    processId={this.state.processId}
                                    edit_details={this.state.edit_details}
                                    mobileHide={this.state.mobileHide}
                                    mobileTab={this.props.mobileTab}
                                    detailView={true}
                                />
                            ) : this.props.deleted_page.currentBigViewSearch ===
                              'outbox' ? (
                                <OutboxBigView
                                    selectedDocId={
                                        this.props.deleted_page.selectedDocId
                                    }
                                    download={() => {
                                        download(
                                            this.props.deleted_page
                                                .selectedDocId
                                        );
                                    }}
                                    openDeletePopUp={() => {}}
                                    statusArray={this.state.statusArray}
                                    branches={this.state.branches}
                                    groups={this.state.groups}
                                    rowTransition={() => {
                                        this.rowTransition();
                                    }}
                                    menuGroup={this.state.menuGroup}
                                    menuBranch={this.state.menuBranch}
                                    menuInbox={this.state.menu_inbox}
                                    showBranch
                                    showDelete={this.state.show_delete}
                                    showDownload={this.state.show_download}
                                    showGroup
                                    showInbox
                                    showNotes
                                    showOwner={this.state.show_owner}
                                    showPrint={this.state.show_print}
                                    showPdf={this.state.show_pdf}
                                    //detailView
                                    goToPage={this.goToPage}
                                    calingFrom='deleted_document'
                                    more_information
                                    updateState={(state) => {
                                        this.setState({ ...state });
                                        this.props.updateState({ ...state });
                                    }}
                                    deleted_page={this.props.deleted_page}
                                    deletedPage={this.props.deletedPage}
                                    isLocked={
                                        this.props.miscPproperties !== undefined
                                            ? this.props.miscPproperties
                                                  .isLocked
                                            : false
                                    }
                                    userPermission={
                                        this.props.deleted_page.permission
                                    }
                                    statuses={
                                        this.props.statuses !== undefined
                                            ? this.props.statuses
                                            : []
                                    }
                                    delstatusId={
                                        this.props.deleted_page.statusId !==
                                        undefined
                                            ? this.props.deleted_page.statusId
                                            : ''
                                    }
                                    delprocessId={
                                        this.props.deleted_page.processId !==
                                        undefined
                                            ? this.props.deleted_page.processId
                                            : ''
                                    }
                                    refreshForNextDocument={() => {
                                        this.refreshForNextDocument();
                                    }}
                                    editDetailsOutbox={
                                        this.state.editDetailsOutbox
                                    }
                                    globalActions={this.state.globalActions}
                                    mobileHide={this.state.mobileHide}
                                    mobileTab={this.props.mobileTab}
                                    allActions={
                                        this.props.deleted_page.allActions
                                    }
                                    //closeBigView={() => {
                                    //this.closeBigView();
                                    //}}
                                />
                            ) : null}
                        </div>
                    ) : null}
                </div>

                <Modal
                    show={this.props.deleted_page.openHistory}
                    onHide={() => {
                        this.closeHistory();
                    }}
                    animation={false}
                    size='lg'
                    aria-labelledby='contained-modal-title-vcenter'
                    centered
                >
                    <Modal.Header
                        closeButton
                        className='d-block justify-content-start history_header'
                    >
                        <Modal.Title className='d-flex d-flex wrap_history align-items-stretch justify-content-between'>
                            <div className='history_popup'>
                                <i className='fa fa-history' />
                                View History
                            </div>
                            <div className='d-flex align-items-center'>
                                <i
                                    title='Close'
                                    className='fa-light fa-xmark d-flex justify-content-end '
                                    aria-hidden='true'
                                    onClick={() => {
                                        this.closeHistory();
                                    }}
                                />
                            </div>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body className='p-0 history-pop'>
                        <DocumentHistroy
                            openHistroy={this.props.deleted_page.openHistory}
                            docId={this.props.deleted_page.selectedDocId}
                        />
                    </Modal.Body>
                </Modal>

                {this.props.deleted_page.single_edit === true ? (
                    <div
                        style={
                            {
                                // height: '800px',
                                // paddingTop: '30px',
                                // position: 'relative',
                            }
                        }
                    >
                        <EditWorkPage
                            // style={{ position: 'sticky' }}
                            updateState={(state) => {
                                this.setState({ ...state });
                                this.props.deletedPage({
                                    ...state,
                                });
                            }}
                            deleteBigView={this.state.deleteBigView}
                            calingFrom='search'
                            show_result={this.props.show_result}
                        />
                    </div>
                ) : null}
            </>
        );
    }
}

const mapDispatchToProps = {
    deletedPage,
    updateSearchState,
    selectedRowDetail,
    getDDLlist: getDDLlist,
};
const mapStateToProps = (state) => {
    return {
        search: state.search,
        deleted_page: state.search.deletedPage,
        configData: state.configReducerPersist.configData,
        selectedRowDetails: state.search.selectedRowDetails,
        miscPproperties: state.search.selectedRowDetails.miscPproperties,
        statuses: state.search.selectedRowDetails.statuses,
        customSearchQueries:
            state.configReducerPersist.configData.customSearchQueries,
        ddl: state.persistDDL.DDL,
    };
};
export default React.memo(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(withRouter(DeletedDocumentPage))
);
