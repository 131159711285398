/* eslint-disable array-callback-return */
import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Nav from 'react-bootstrap/Nav';
import Spinner from 'react-bootstrap/Spinner';
import DatePicker from 'react-date-picker';
import ByPatient from '../../../components/patient/ByPatient';
import ByOrder from './ByOrder';
import DetailCreatePatient from './DetailCreatePatient';
import CreateNewPatient from '../../../components/patient/CreateNewPatient';
import CreateNewOrder from './CreateNewOrder';
import {
    recordSetSearch,
    getAttachmentLocData,
    PostMessageLogout,
    configurationSqlId,
} from '../../api/api';
import {
    formatHeaderNameWithOutSpace,
    formatNameWithSpaceToUnderscore,
    formatDate,
    GetFieldData,
    EpisodeSearch,
    PatientWithUnderScore,
    getDropDownDataFromApi,
    BranchIdSearch,
    stringEmptyOrUndefined,
    OrderWithUnderScore,
    encodeParameter,
    showErrorToast,
} from '../../../components/commonfunction';
import ViewEditPatient from '../../../components/patient/ViewEditPatient';
import ViewEpisode from './ViewEpisode';
import { getDDLlist } from '../../_redux/actions/configActions';
import { editDetailPage, inboxPage } from '../../_redux/actions/inboxActions';
import FormFileds from './formTools/FormFileds';

const _ = require('lodash');

export class EditDetails extends React.Component {
    _isMounted = false;
    constructor(props) {
        super(props);
        this.state = {
            detailDDL: 'Attach to chart',
            attachmentTypeDDL: [],
            attachmentLocationDDL: [],
            newreferralDDL: [],
            patient_filter: {},
            order_filter: {},
            by_patient: true,
            by_order: false,
            rowDetails: { comments: '' },
            create_patient: false,
            refreshAttachmentLoc: true,
            currentNewReferrel: 'No',
            refreshNewReferrel: true,
            episodeData: [],
            refreshEpisodeVisit: true,
            episodeVisitData: [],
            selectedEpisode: {},
            isUploadPage: this.props.isUploadPage,
            isDetailBigView: this.props.isDetailBigView,
            refreshEdit: false,
            formData: {
                attachment_type: '',
                attachment_location: '',
                comments: '',
                new_referral: 'No',
                team: '',
                date_of_birth_validation: '',
                signed_date: null,
                episodeid: '',
                episode_visit: '',
            },
            dynamicFormDataArray: [],
            detailReqFields: [],
            currentLookup: 'byPatient',
            lookupRefresh: true,
            minDate: new Date('1/10/2022'),
            todaysDate: new Date(),
            date_of_birth_validation: '',
            allowedFields: [],
            patientCreated: false,
            commentTextCount: 250,
            commentTextFlag: 0,
            validateDetail: false,
            submitCreatePatient: false,
            submittingViaUpload: false,
            open_create_patient: true,
            createPatientData: {},
            open_edit_patient: true,
            validateCreate: false,
            formFor: 'patient',
            byOrderEdit: false,
            agency_id: [],
            patient_filter_display: '', // aiswarya added
            documentBranch: undefined,
            duplicateAttribute: [],
            agencyBranch: '',
            attachmentTypeBranch: '',
            newReferralSet: true,
            episodeLookup: false,
            byOrderEpisodeId: '',
            epiosdeReadOnly: false,
            visitDate: [],
        };
    }

    getTransitonDropDownData() {
        const fieldData = GetFieldData({ configData: this.props.configData });
        if (!this.props.ddl.hasOwnProperty('attachmenttype')) {
            getDropDownDataFromApi(fieldData.attachmentTypeId).then((data) => {
                //if (this._isMounted) {
                if (data.values) {
                    this.props.getDDLlist({
                        [formatHeaderNameWithOutSpace(data.name)]: data.values,
                    });
                }
                //}
            });
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
    }
    // this function included as per crisina disscusion 19/10/2023
    async getSingleOrder(ordernumber) {
        try {
            let orderSearchText;

            // Search for the field related to "Order Number"
            this.props.configData.fields.map((fieldData, index) => {
                if (fieldData.name === 'Order Number') {
                    orderSearchText = `fieldSearch=${fieldData.fieldId}%3D${ordernumber}%2A`;
                }
            });

            // Ensure orderSearchText is defined before proceeding
            if (!orderSearchText) {
                throw new Error(
                    'Order number field is not found in config data'
                );
            }

            // Perform the record set search
            const resultOrder = await recordSetSearch(
                this.state.orderRecordsetId,
                orderSearchText
            );

            // Handle response status
            if (resultOrder.status !== 200) {
                throw new Error(
                    `Failed to retrieve order, status code: ${resultOrder.status}`
                );
            }

            // Parse the JSON data
            const orderData = await resultOrder.json();

            // Ensure orderData exists
            if (!orderData) {
                throw new Error('No data returned from recordSetSearch');
            }

            // Process the order data
            const searchResult = await OrderWithUnderScore(orderData);

            if (searchResult.length === 1) {
                let form_data = {
                    ...this.state.formData,
                    episodeid: searchResult[0].episodeid,
                };
                await this.setState({
                    formData: form_data,
                    patient_filter: {
                        ...this.state.patient_filter,
                        episodeid: searchResult[0].episodeid,
                    },
                    byOrderEpisodeId: searchResult[0].episodeid,
                });
            } else {
                throw new Error(
                    'Order search did not return exactly one result'
                );
            }
        } catch (error) {
            console.error('Error in getSingleOrder:', error);
        }
    }

    async componentDidMount() {
        await this.initializeEditDetail();
        this._isMounted = true;
    }

    initializeEditDetail = async () => {
        this.getTransitonDropDownData();
        const fieldData = GetFieldData({
            configData: this.props.configData,
        });
        this.setState({ allFieldId: fieldData });
        const todaysDate = formatDate(this.state.todaysDate, 'YYYY-MM-DD');
        this.setState({ todaysDate: todaysDate });

        if (this.state.isUploadPage !== true) {
            this.validateFromRulesJson();
        }

        let configuredRecordsets = [];
        let patientFieldIdArray;
        let modifyPatientFieldIdArray;
        let orderFieldIdArray;
        let modifyOrderFieldIdArray;
        let patientRecordsetId;
        let modifyPatientRecordsetId;
        let orderRecordsetId;
        let modifyOrderRecordsetId;
        let patientModifiableRecordsetId;
        let modifyPatientModifiableRecordsetId;
        let orderModifiableRecordsetId;
        let modifyOrderModifiableRecordsetId;
        let episodeRecordsetId;
        // eslint-disable-next-line no-unused-vars
        let episodevisitRecordsetId;
        configuredRecordsets = this.props.configuredRecordsets;
        const allRecordSetID = {};
        if (configuredRecordsets !== undefined) {
            configuredRecordsets.map((data) => {
                if (formatHeaderNameWithOutSpace(data.name) === 'patientdata') {
                    patientFieldIdArray = data.fields;
                    patientRecordsetId = data.recordsetId;
                    patientModifiableRecordsetId = data.modifiableRecordsetId;
                }

                if (
                    formatHeaderNameWithOutSpace(data.name) ===
                    'patientdatamodify'
                ) {
                    modifyPatientFieldIdArray = data.fields;
                    modifyPatientRecordsetId = data.recordsetId;
                    modifyPatientModifiableRecordsetId =
                        data.modifiableRecordsetId;
                }

                if (formatHeaderNameWithOutSpace(data.name) === 'orderdata') {
                    orderFieldIdArray = data.fields;
                    orderRecordsetId = data.recordsetId;
                    orderModifiableRecordsetId = data.modifiableRecordsetId;
                }

                if (
                    formatHeaderNameWithOutSpace(data.name) ===
                    'orderdatamodify'
                ) {
                    modifyOrderFieldIdArray = data.fields;
                    modifyOrderRecordsetId = data.recordsetId;
                    modifyOrderModifiableRecordsetId =
                        data.modifiableRecordsetId;
                }
                if (formatHeaderNameWithOutSpace(data.name) === 'episodedata') {
                    episodeRecordsetId = data.recordsetId;
                }
                if (
                    formatHeaderNameWithOutSpace(data.name) ===
                    'episodevisitdata'
                ) {
                    episodevisitRecordsetId = data.recordsetId;
                }
                const fieldIDArray = {};
                if (data.hasOwnProperty('fields') && data.fields.length > 0) {
                    data.fields.map((field, index) => {
                        fieldIDArray[
                            formatNameWithSpaceToUnderscore(field.name)
                        ] = field;
                    });
                    data.fields_obj = fieldIDArray;
                }
                const name = formatNameWithSpaceToUnderscore(data.name);
                allRecordSetID[name] = data;
                return data;
            });
        }
        this.setState({ allRecordSetID: allRecordSetID });
        this.setState({
            patientModifiableRecordsetId: patientModifiableRecordsetId,
            patientFieldIdArray: patientFieldIdArray,
            patientRecordsetId: patientRecordsetId,
            modifyPatientFieldIdArray: modifyPatientFieldIdArray,
            modifyPatientRecordsetId: modifyPatientRecordsetId,
            modifyPatientModifiableRecordsetId:
                modifyPatientModifiableRecordsetId,
            orderFieldIdArray: orderFieldIdArray,
            orderRecordsetId: orderRecordsetId,
            orderModifiableRecordsetId: orderModifiableRecordsetId,
            modifyOrderFieldIdArray: modifyOrderFieldIdArray,
            modifyOrderRecordsetId: modifyOrderRecordsetId,
            modifyOrderModifiableRecordsetId: modifyOrderModifiableRecordsetId,
            episodeRecordsetId: episodeRecordsetId,
            attachmentTypeBranch: '',
        });

        if (this.props.ddl.hasOwnProperty('newReferralDDL')) {
            await this.setState({
                newReferralDDL: this.props.ddl.newreferral,
            });
        }

        this.setState({
            by_patient: true,
        });

        const allFields = this.props.configData.fields;
        let rowDetails = [];

        if (
            this.props.selectedRowDetails !== '' &&
            'fields' in this.props.selectedRowDetails
        ) {
            rowDetails = this.props.selectedRowDetails.fields;
        }

        let patient_filter_temp = {};
        let finalRowDetail = { comments: '' };
        if (rowDetails.length > 0) {
            await rowDetails.map(async (rowdetail) => {
                await allFields.map((allfields) => {
                    const temp = { ...rowdetail };
                    const value = rowdetail.value;
                    if (rowdetail.fieldId === allfields.fieldId) {
                        temp.fieldName = allfields.name;

                        const removeSpace = formatNameWithSpaceToUnderscore(
                            allfields.name
                        );

                        if (
                            removeSpace === 'attachment_type' ||
                            removeSpace === 'attachment_location' ||
                            removeSpace === 'team' ||
                            removeSpace === 'comments' ||
                            removeSpace === 'new_referral' ||
                            removeSpace === 'date_of_birth_validation' ||
                            removeSpace === 'signed_date' ||
                            removeSpace === 'payor_source' ||
                            removeSpace === 'care_type' ||
                            removeSpace === 'episodeid' ||
                            removeSpace === 'select_an_episode' ||
                            removeSpace === 'order_number' ||
                            removeSpace === 'order_date' ||
                            removeSpace === 'episodeid' ||
                            removeSpace === 'select_an_episode' ||
                            removeSpace === 'episode_status' ||
                            removeSpace === 'episode_status' ||
                            removeSpace === 'episode_start' ||
                            removeSpace === 'episode_end' ||
                            removeSpace === 'episode_start' ||
                            removeSpace === 'medical_record_' ||
                            removeSpace === 'referral_date' ||
                            removeSpace === 'visit_id' ||
                            removeSpace === 'visit_date'
                        ) {
                            finalRowDetail[removeSpace] = value;
                        }
                        if (removeSpace === 'referral_date') {
                            this.setState({
                                referralDate: new Date(value),
                            });
                        }
                        if (
                            removeSpace === 'branch' ||
                            removeSpace === 'agencyid' ||
                            removeSpace === 'date_of_birth' ||
                            removeSpace === 'service_line' ||
                            removeSpace === 'patient_name' ||
                            removeSpace === 'gender' ||
                            removeSpace === 'patient_id' ||
                            removeSpace === 'order_number' ||
                            removeSpace === 'order_date' ||
                            removeSpace === 'episodeid' ||
                            removeSpace === 'patient_address' ||
                            removeSpace === 'patient_city' ||
                            removeSpace === 'patient_state' ||
                            removeSpace === 'patient_zip_code' ||
                            removeSpace === 'patient_phone_' ||
                            removeSpace === 'patient_mobile_phone_' ||
                            removeSpace === 'patient_first_name' ||
                            removeSpace === 'patient_last_name'
                        ) {
                            patient_filter_temp[removeSpace] = value;
                        }
                    }
                });
            });
        }

        if (patient_filter_temp.hasOwnProperty('order_number')) {
            await this.setState({
                currentLookup: 'byOrder',
            });
            patient_filter_temp.fromViewDetail = true;
            await this.getSingleOrder(patient_filter_temp.order_number);
        } else if (
            patient_filter_temp.hasOwnProperty('patient_id') ||
            patient_filter_temp.hasOwnProperty('patient_name')
        ) {
            patient_filter_temp.patientRecord = true;
            await this.setState({
                currentLookup: 'byPatient',
            });
        }
        if (finalRowDetail.new_referral !== 'No') {
            this.setState({ createPatientData: patient_filter_temp });
        }

        if (
            patient_filter_temp.hasOwnProperty('branch') &&
            patient_filter_temp.hasOwnProperty('patient_id')
        ) {
            if (patient_filter_temp.hasOwnProperty('agencyid')) {
                this.setState({ agencyid: patient_filter_temp.agencyid });
            }
            this.setState({ agency_id: '' });

            this.props.inboxPage({
                currentBranch: patient_filter_temp.branch,
            });
        }
        if (this.state.byOrderEpisodeId) {
            patient_filter_temp = {
                ...patient_filter_temp,
                episodeid: this.state.byOrderEpisodeId,
            };
        }
        this.props.editDetailPage({ patient_filter: patient_filter_temp });
        await this.setState({ patient_filter: patient_filter_temp }, () => {
            this.props.updateState({
                patient_filter: patient_filter_temp,
            });
        });

        if ('comments' in finalRowDetail) {
            if (finalRowDetail.comments !== '') {
                finalRowDetail.comments = finalRowDetail.comments.replace(
                    /<br\s*[/]?>/gi,
                    '\n'
                );
            }
        }

        if (
            'date_of_birth_validation' in finalRowDetail ||
            finalRowDetail.hasOwnProperty('signed_date')
        ) {
            if (finalRowDetail.date_of_birth_validation) {
                const date_of_birth_validation = new Date(
                    finalRowDetail.date_of_birth_validation
                );
                finalRowDetail.date_of_birth_validation =
                    date_of_birth_validation;
            }

            if (finalRowDetail.signed_date) {
                const signed_date = new Date(finalRowDetail.signed_date);
                finalRowDetail.signed_date = signed_date;
            }
        }

        if ('date_of_birth' in finalRowDetail) {
            if (finalRowDetail.date_of_birth !== '') {
                const date_of_birth = new Date(
                    formatDate(finalRowDetail.date_of_birth, 'MM/DD/YYYY')
                );
                finalRowDetail.date_of_birth = date_of_birth;
            }
        }

        let finalRowDetailTemp = {
            ...this.state.formData,
            ...finalRowDetail,
        };

        finalRowDetailTemp = {
            ...finalRowDetailTemp,
            episodeid: this.state.byOrderEpisodeId,
        };

        await this.setState({ formData: finalRowDetailTemp }, () => {
            if (document.getElementsByClassName('nav-link active')) {
            }
        });

        if (!this.state.isUploadPage) {
            const reqField = ['attachment_type', 'attachment_location'];
            this.setState(
                {
                    detailReqFields: reqField,
                },
                () => {
                    this.updateState({ detailReqFields: reqField });
                }
            );

            if (
                this.state.formData.hasOwnProperty('attachment_type') &&
                this.state.formData.attachment_type !== undefined
            ) {
                await this.handleAttachmentType(
                    'attachment_type',
                    '',
                    this.state.formData.attachment_type
                );
            }

            if (
                this.state.formData.hasOwnProperty('select_an_episode') &&
                this.state.formData.select_an_episode
            ) {
                this.handleEpisodeChange(
                    'select_an_episode',
                    this.state.formData.select_an_episode
                );
            } else if (
                finalRowDetail.hasOwnProperty('select_an_episode') &&
                finalRowDetail.select_an_episode !== ''
            ) {
                this.handleEpisodeChange(
                    'select_an_episode',
                    finalRowDetail.select_an_episode
                );
            }
            if (this.state.currentLookup === 'byPatient') {
                let by_patient_form = {};
                if (!this.state.formData.hasOwnProperty('attachment_type')) {
                    by_patient_form.attachment_type = '';
                }
                if (
                    !this.state.formData.hasOwnProperty('attachment_location')
                ) {
                    by_patient_form.attachment_location = '';
                }
                by_patient_form = {
                    ...by_patient_form,
                    ...this.state.formData,
                };
                await this.setState({ formData: by_patient_form });
            }
        }
        if (
            (this.props.isDetailBigView &&
                this.state.refreshNewReferrel &&
                this.props.rulesJson.hasOwnProperty('indexingSettings') &&
                this.props.rulesJson.indexingSettings.referralsUsed) ||
            this.state.isUploadPage
        ) {
            if (this.state.formData.new_referral !== '') {
                this.setFormOnChange(
                    'new_referral',
                    this.state.formData.new_referral,
                    ''
                );
                this.setState(
                    {
                        currentNewReferrel: this.state.formData.new_referral,
                        refreshNewReferrel: false,
                    },
                    () => {
                        this.setState({ refreshNewReferrel: true });
                    }
                );
            } else {
                this.setFormOnChange('new_referral', 'No', '');
                this.setState(
                    { currentNewReferrel: 'No', refreshNewReferrel: false },
                    () => {
                        this.setState({ refreshNewReferrel: true });
                    }
                );
            }
        }
        if (this.state.currentLookup === 'byOrder') {
            await this.byOrder('initialView');
            await this.setState({ patient_filter: patient_filter_temp }, () => {
                this.props.updateState({
                    patient_filter: patient_filter_temp,
                });
            });
        }
        this.setState({
            refreshEdit: true,
        });

        if (
            this.props.selectedRowDetails?.miscPproperties?.docId ==
            this.props.inbox_page?.editDetailsSwitch?.docId
        ) {
            if (
                this.props.inbox_page?.editDetailsSwitch?.editType ==
                'byPatient'
            ) {
                this.byPatient('initialView');
            }

            if (
                this.props.inbox_page?.editDetailsSwitch?.editType == 'byOrder'
            ) {
                this.byOrder('initialView');
            }
        }

        if (
            !(
                this.props.selectedRowDetails?.miscPproperties?.docId ==
                this.props.inbox_page?.editDetailsSwitch?.docId
            ) &&
            this.state.currentLookup === 'byOrder'
        ) {
            this.byOrder('initialView');
        }

        if (this.state.formData.new_referral !== '') {
            if (
                this.state.formData.new_referral === 'Incomplete' ||
                this.state.formData.new_referral === 'Yes'
            ) {
                this.openCreateNewPatient('initialView');
            }

            if (this.state.formData.new_referral === 'No') {
                if (this.state.patient_filter.hasOwnProperty('order_number')) {
                    if (
                        !(
                            this.props.selectedRowDetails.miscPproperties
                                .docId ==
                            this.props.inbox_page?.editDetailsSwitch?.docId
                        )
                    ) {
                        this.byOrder('initialView');
                        this.setState({
                            currentLookup: 'byOrder',
                        });
                    }
                } else {
                    if (
                        this.state.patient_filter.patient_id !== undefined &&
                        this.state.patient_filter.patient_id !== null &&
                        this.state.patient_filter.patient_id !== null
                    ) {
                        await this.setState({
                            view_patient: true,
                        });

                        this.setState({
                            enableSingleSearch: true,
                            documentBranch: this.state.patient_filter.branch,
                            currentLookup: 'byPatient',
                        });
                    }
                }
            }
        }

        if (patient_filter_temp.branch) {
            await this.setAttachmentTypeDDL(patient_filter_temp.branch);
        }

        await this.getAllowedFields();
        await this.uploadPageInitial('initialView');
        let arrvar = [];
        if (
            this.props.selectedRowDetails &&
            this.props.selectedRowDetails.miscPproperties &&
            this.props.selectedRowDetails.miscPproperties.rulesJson
        ) {
            Object.entries(
                (() => {
                    var rule =
                        typeof this.props.selectedRowDetails.miscPproperties
                            .rulesJson == 'object'
                            ? this.props.selectedRowDetails.miscPproperties
                                  .rulesJson.ValidationRules
                            : JSON.parse(
                                  this.props.selectedRowDetails.miscPproperties
                                      .rulesJson
                              ).ValidationRules;

                    var afterChangeJSON = JSON.stringify(rule);
                    afterChangeJSON = afterChangeJSON.replace(
                        /Location Type/g,
                        'Attachment Location'
                    );

                    return JSON.parse(afterChangeJSON);
                })()
            ).map((value, index) => {
                return [...value[1]].map((uiValue, index) => {
                    return [...uiValue?.attributerefs]
                        ?.reverse()
                        ?.map((attributerefs, index) => {
                            let notReqFiled =
                                this.state.by_order &&
                                this.state.formData.new_referral === 'No'
                                    ? [
                                          'Attachment Type',
                                          'Attachment Location',
                                          'Select an Episode',
                                      ]
                                    : [
                                          'Attachment Type',
                                          'Attachment Location',
                                          'Select an Episode',
                                      ];
                            if (
                                arrvar.includes(attributerefs) === false &&
                                notReqFiled.includes(attributerefs) === false
                            ) {
                                arrvar.push(attributerefs);
                                this.setState({
                                    duplicateAttribute: [
                                        ...this.state.duplicateAttribute,
                                        attributerefs,
                                    ],
                                });
                            }
                        });
                });
            });
        }
        await this.setState({
            formData: finalRowDetailTemp,
        });
        this.props.updateState({
            formData: finalRowDetailTemp,
        });
        if (
            patient_filter_temp.hasOwnProperty('order_number') &&
            finalRowDetailTemp.hasOwnProperty('attachment_location') &&
            finalRowDetailTemp.attachment_location === 'EPISODE'
        ) {
            if (this.state.episodeData.length === 1) {
                let form_data = {
                    ...finalRowDetailTemp,
                    ...this.state.episodeData[0],
                };
                await this.setState(
                    {
                        formData: form_data,
                    },
                    this.props.updateState({
                        formData: form_data,
                    })
                );
            }
        }
    };
    commonErrorMessage = () => {
        showErrorToast('An error has occurred');
    };
    getAgencyFromBranch = async (branch) => {
        let searchResult = [];

        // Check for branch_data before accessing the deeply nested fieldId
        const branchId =
            this.state?.allRecordSetID?.branch_data?.fields_obj?.branch
                ?.fieldId;

        if (!branchId) {
            console.error('branchId is undefined or null');
            return; // Early exit if branchId is not available
        }

        const searchText = `fieldSearch=${branchId}=${encodeParameter(branch)}`;

        this.setState({
            agencyBranch: branch,
        });

        const response = await recordSetSearch(
            this.state.allRecordSetID.branch_data.recordsetId,
            searchText
        );

        let data;
        if (response?.status === 200) {
            data = await response.json();
        } else {
            throw new Error('Error in recordSetSearch');
        }

        if (data) {
            const { dataRows } = data.dataTable;
            if (dataRows.length !== 0) {
                searchResult = EpisodeSearch(data);
                let branch_id = BranchIdSearch(data);
                const firstNonEmptyAgency = await searchResult.find(
                    (item) => item.agencyid !== ''
                );
                this.setState(
                    {
                        agency_id: searchResult,
                        agencyid:
                            firstNonEmptyAgency?.agencyid &&
                            firstNonEmptyAgency.agencyid,
                        patient_filter: {
                            ...this.state.patient_filter,
                            agencyid:
                                firstNonEmptyAgency?.agencyid &&
                                firstNonEmptyAgency.agencyid,
                            branch_id: branch_id,
                        },
                    },
                    () => {}
                );
                await this.props.updateState({
                    patient_filter: {
                        ...this.state.patient_filter,
                        agencyid:
                            firstNonEmptyAgency?.agencyid &&
                            firstNonEmptyAgency.agencyid,
                        branch_id: branch_id,
                    },
                });
            }
        }

        searchResult = this.state.agency_id;

        let agencyid = '';
        const allAgencyid = [];

        if (Array.isArray(searchResult) && searchResult.length > 0) {
            searchResult.map((agncy) => {
                if (agncy.agencyid !== '') {
                    allAgencyid.push(agncy.agencyid);
                }
            });
        }

        if (allAgencyid.length === 0) {
            return true;
        }
        if (allAgencyid.length > 0) {
            agencyid = allAgencyid[0];
        }

        this.setState({ agency_id: agencyid });
    };

    setAttachmentTypeDDL = async (branch, patient = {}) => {
        this.setState({
            attachmentTypeBranch: branch,
        });
        const fieldData = GetFieldData({
            configData: this.props.configData,
        });

        await this.getAgencyFromBranch(branch);

        const agencyid = stringEmptyOrUndefined(this.state.agencyid)
            ? this.state.agencyid
            : this.state.patient_filter.agencyid;
        const agencySearchText = `?fieldSearch=${fieldData.agencyid}%3D${agencyid}%2A`;

        if (
            this.state.view_patient ||
            this.state.currentNewReferrel.toUpperCase() !== 'NO'
        ) {
            getAttachmentLocData(fieldData.attachmentTypeId, agencySearchText)
                .then((response) => {
                    if (response.status === 200) {
                        return response.json();
                    } else {
                        this.commonErrorMessage();
                        return;
                    }
                })
                .then((data) => {
                    if (data.hasOwnProperty('values')) {
                        this.setState({
                            attachmentTypeDDL: data.values,
                        });
                    }
                });
        }

        await this.setReqField();
    };

    searchSinglePatient = async (patientId) => {
        let searchText = '';
        if (patientId) {
            if (this.state.patientFieldIdArray !== '') {
                this.state.patientFieldIdArray.map((fieldData, index) => {
                    if (fieldData.name === 'Patient ID') {
                        searchText += `fieldSearch=${fieldData.fieldId}%3D${patientId}%2A`;
                    }
                });
            }
            let status;

            await recordSetSearch(this.state.patientRecordsetId, searchText)
                .then(async (response) => {
                    status = response.status;
                    return response.json();
                })
                .then(async (data) => {
                    if (status === 200) {
                        const searchResult = await PatientWithUnderScore(data);
                        if (searchResult.length > 0) {
                            await this.setState({
                                singlePatient: searchResult[0],
                            });
                            const search = searchResult[0];
                            if (search.id > 0 || search.id === 0) {
                                this.setState({
                                    patientIsNegative: false,
                                });
                            } else {
                                this.setState({
                                    patientIsNegative: true,
                                });
                            }

                            // this.open_patient_search(searchResult[0]);
                        }
                    }
                });
        }
    };

    open_patient_search = async (search) => {
        const state = {
            patient_filter: search,
            open_patient_dropdown: false,
            view_patient: false,
            selected_patient_id: search.patientid,
            create_patient: false,
            edit_patient: false,
            // patientIsNegative: false,
        };
        await this.setState(state, () => {
            this.props.updateState(state);
        });
        if (search.id > 0 || search.id === 0) {
            await this.setState({
                patientIsNegative: false,
            });
        } else {
            await this.setState({
                patientIsNegative: true,
            });
        }

        await this.setState({ view_patient: true });
    };

    getAllowedFields = async () => {
        const allowedFields = [];
        await this.props.uploadTypes.map((data) => {
            //if (this._isMounted) {
            if (data.type_flag === 'Upload') {
                data.allowedFieldNames.map((field) => {
                    allowedFields.push(formatNameWithSpaceToUnderscore(field));
                });
            }
            //}
        });
        this.setState({ allowedFields: allowedFields });
    };

    uploadPageInitial = async (view) => {
        if (this.state.isUploadPage) {
            if (document.getElementsByClassName('nav-link active')) {
                const id = document.getElementsByClassName('nav-link active');
                let selected = '';
                if ('newReferrel' in id) {
                    selected = id.newReferrel.outerText;
                }
                this.setFormOnChange('new_referral', selected, '');
            }

            this.setState({ validateDetail: this.props.validateDetail });
            this.setState({
                detailReqFields: this.props.uploadReqFields,
            });
            if (view === 'initialView') {
                const form_data = {
                    attachment_type: '',
                    attachment_location: '',
                    date_of_birth_validation: '',
                    comments: '',
                    team: '',
                    // new_referral: 'No',
                    // ...this.props.formData,
                };

                form_data.new_referral = 'No';

                await this.setState({ formData: form_data }, () => {
                    this.updateState({ formData: form_data });
                });
            }
        }
    };

    validateFromRulesJson = async () => {
        const ValidationRules = this.props.rulesJson.ValidationRules;
        if (
            ValidationRules !== undefined &&
            ValidationRules.location_type_client[0].validation.id === 'required'
        ) {
            await this.setState({
                detailReqFields: [...this.state.detailReqFields],
            });
        }
        if (this.state.isUploadPage !== true) {
            this.updateState({ detailReqFields: this.state.detailReqFields });
        }
    };

    componentDidUpdate(prevProps, prevState) {
        if (this._isMounted === false) {
            return;
        }

        if (
            Array.isArray(this.state.duplicateAttribute) &&
            this.state.duplicateAttribute.length > 0 &&
            (prevState.by_order !== this.state.by_order ||
                JSON.stringify(prevState.duplicateAttribute) !==
                    JSON.stringify(this.state.duplicateAttribute))
        ) {
            if (this.state.by_order) {
                const temp = this.state.duplicateAttribute;
                const tempData = Array.isArray(temp)
                    ? temp.filter((value) => value != 'Signed Date')
                    : [];
                this.setState({
                    duplicateAttribute: tempData,
                });
            } else {
                const temp = [...this.state.duplicateAttribute];
                const index = temp.findIndex((value) => value == 'Signed Date');
                if (index == -1) {
                    temp.push('Signed Date');
                    this.setState({
                        duplicateAttribute: temp,
                    });
                }
            }
        }

        var didSwitchLookupType =
            (prevState.by_order && this.state.by_patient) ||
            (prevState.by_patient && this.state.by_order);

        if (didSwitchLookupType) this.clearFormData();

        if (
            prevProps.inbox_page.currentBranch !==
            this.props.inbox_page.currentBranch
        ) {
            this.setState({
                agency_id: '',
                //attachmentTypeBranch: '',
            });

            if (stringEmptyOrUndefined(this.state.patient_filter.agencyid)) {
                this.setState({
                    agencyid: this.state.patient_filter.agencyid,
                });
            }

            if (this.state.patient_filter.hasOwnProperty('agencyid')) {
                this.setAttachmentTypeDDL(
                    this.props.inbox_page.currentBranch,
                    this.state.patient_filter
                );
            }
        } else if (
            typeof this.state.patient_filter === 'object' &&
            this.state.patient_filter !== '' &&
            this.state.patient_filter !== undefined &&
            this.state.patient_filter !== null &&
            Object.keys(this.state.patient_filter).length > 0 &&
            this.state.patient_filter.hasOwnProperty('branch') &&
            prevState.patient_filter !== undefined
        ) {
            if (
                prevState.currentNewReferrel !== this.state.currentNewReferrel
            ) {
                this.setState({
                    patient_filter: {
                        ...this.state.patient_filter,
                        branch: '',
                    },
                });
            }

            if (
                (this.state.patient_filter.branch !== '' &&
                    prevState.patient_filter.branch !==
                        this.state.patient_filter.branch) ||
                prevState.patient_filter.patient_id !==
                    this.state.patient_filter.patient_id
            ) {
                if (this.state.patient_filter?.branch) {
                    this.setState({ agency_id: '' });

                    this.setAttachmentTypeDDL(this.state.patient_filter.branch);
                    if (this.state?.formData?.attachment_type) {
                        this.attachmentLocationApi(
                            this.state.formData.attachment_type
                        );
                    }
                }
                if (this.state.by_order !== true) {
                    this.setState({
                        selectedEpisode: {},
                        //attachmentTypeBranch:'',
                        formData: {
                            ...this.state.formData,
                            attachment_type: '',
                            attachment_location: '',
                            select_an_episode: '',
                            visit_id: '',
                        },
                    });
                }

                if (
                    stringEmptyOrUndefined(this.state.patient_filter.agencyid)
                ) {
                    this.setAttachmentTypeDDL(
                        this.state.patient_filter.branch,
                        this.state.patient_filter
                    );
                }
            } else if (
                this.state.patient_filter.branch !==
                this.props.inbox_page.currentBranch
            ) {
                // this condition not used

                if (this.state.patient_filter?.branch) {
                    this.setState({ agency_id: '' });

                    if (this.state.patient_filter.hasOwnProperty('agencyid')) {
                        this.setAttachmentTypeDDL(
                            this.state.patient_filter.branch,
                            this.state.patient_filter
                        );
                    }

                    if (this.state?.formData?.attachment_type) {
                        this.attachmentLocationApi(
                            this.state.formData.attachment_type
                        );
                    }
                    this.props.inboxPage({
                        currentBranch: this.state.patient_filter.branch,
                    });
                }
            } else if (
                this.props?.state?.patient_filter?.branch !==
                    this.state.patient_filter.branch &&
                !this.props.isUploadPage
            ) {
                this.props.inboxPage({
                    currentBranch: this.props?.state?.patient_filter.branch,
                });
                this.setState({
                    patient_filter: this.props?.state?.patient_filter,
                    selectedEpisode: {},
                    formData: {
                        ...this.state.formData,
                        attachment_type: '',
                        attachment_location: '',
                        select_an_episode: '',
                        visit_id: '',
                    },
                });

                this.setAttachmentTypeDDL(
                    this.props?.state?.patient_filter.branch
                );
            }
        }

        if (this.props.state !== prevProps.state) {
            const bigViewstate = this.props.state;
            const update_state = {};
            for (const property in bigViewstate) {
                if (
                    property === 'submitCreatePatient' ||
                    property === 'submittingViaBigView' ||
                    property === 'validateDetail'
                ) {
                    update_state[property] = bigViewstate[property];
                }
            }
            this.setState({ ...update_state });
        }

        if (this.props.isUploadPage === true) {
            if (
                prevProps.submitCreatePatient !== this.props.submitCreatePatient
            ) {
                this.setState({
                    submitCreatePatient: this.props.submitCreatePatient,
                });
            }

            if (
                prevProps.submittingViaUpload !== this.props.submittingViaUpload
            ) {
                this.setState({
                    submittingViaUpload: this.props.submittingViaUpload,
                });
            }

            if (prevProps.validateDetail !== this.props.validateDetail) {
                this.uploadPageInitial();
            }

            if (prevProps.uploadReqFields !== this.props.uploadReqFields) {
                this.setState({
                    detailReqFields: this.props.uploadReqFields,
                });
            }
        }

        if (
            this._isMounted == true &&
            prevProps.selectedRowDetails?.miscPproperties?.docId !==
                this.props.selectedRowDetails?.miscPproperties?.docId
        ) {
            this.initializeEditDetail();
        }
    }

    handleFormOnChange = (state, event) => {
        if (state === 'visit_id') {
            let id = event.target.value;
            let visit_date = this.state.visitDate.find(
                (item) => item.visit_id === id
            )?.visit_date;
            const formData = {
                ...this.state.formData,
                [state]: event.target.value,
                ['visit_date']: visit_date,
            };
            this.setState({ formData: formData }, () => {
                this.updateState({ formData: formData });
            });
        } else {
            let formData = {
                ...this.state.formData,
                [state]: event.target.value,
            };
            if (state === 'attachment_location') {
                formData.episodeid = '';
                formData = {
                    ...formData,
                    episodeid: '',
                    select_an_episode: '',
                    visit_id: '',
                };
            }
            this.setState({ formData: formData }, () => {
                if (state === 'attachment_location') {
                    if (this.state.by_patient) {
                        this.setReqField();
                    }
                }
                this.updateState({ formData: formData });
            });
        }

        if (state === 'comments') {
            const length = event.target.value.length;
            const countValue = 250 - length;
            if (length > 250) {
                this.setState({ commentTextCount: 0 });
                this.setState({ commentTextFlag: 1 });
            } else {
                this.setState({ commentTextCount: countValue });
                this.setState({ commentTextFlag: 1 });
            }
        }
    };

    setDate = (state, date) => {
        this.setState({ [state]: date });
        const formData = {
            ...this.state.formData,
            [state]: date,
        };
        this.setState({ formData: formData }, () => {
            this.updateState({ formData: formData });
        });
    };

    setDate1 = async (state, date) => {
        this.setState({
            referralDate: date,
        });

        const formData = {
            ...this.state.formData,
            [state]: formatDate(date, 'MM/DD/YYYY'),
        };

        await this.setState({ formData: formData }, () => {
            this.updateState({ formData: formData });
        });
    };

    handleAttachmentType = async (state, event, initialData) => {
        let value = '';

        if (initialData !== undefined) {
            value = initialData;
        } else {
            value = event.target.value;
        }
        const formData = {
            ...this.state.formData,
            [state]: value,
            attachment_location: '',
        };
        this.setState(
            {
                formData: formData,
                //refreshAttachmentLoc: false,
                selectedEpisode: {},
                episodeVisitData: [],
            },
            () => {
                this.updateState({ formData: formData });
            }
        );

        let branch = this.props?.patient_filter?.branch
            ? this.props.patient_filter.branch
            : this.props.inbox_page.currentBranch;

        /*  await this.getAgencyFromBranch(branch); */
        await this.attachmentLocationApi(value);
        /* await this.setReqField();*/
    };

    handleEpisodeChange = async (state, event) => {
        if (event !== '') {
            const episode_value = event;
            let selectedValue;

            this.state.episodeData.map((data) => {
                if (data.select_an_episode === episode_value) {
                    selectedValue = data;
                }
            });
            const custom_selected = {};

            for (const property in selectedValue) {
                if (
                    property === 'episode_end' ||
                    property === 'episode_start' ||
                    property === 'episode_status' ||
                    property === 'episodeid' ||
                    property === 'select_an_episode' ||
                    property === 'branch' ||
                    property === 'medical_record_'
                ) {
                    custom_selected[property] = selectedValue[property];
                }
            }

            let formData = {
                ...this.state.patient_filter,
                ...this.state.formData,
                select_an_episode: custom_selected.select_an_episode,
                episodeid: custom_selected.episodeid,
            };
            formData = {
                ...formData,
                ...custom_selected,
            };
            this.setState(
                {
                    formData: formData,
                    selectedEpisode: custom_selected,
                },
                () => {
                    this.updateState({ formData: formData });
                }
            );

            if (
                this.state.formData.attachment_location
                    ?.toLowerCase()
                    .includes('episode') &&
                this.state.formData.attachment_location
                    ?.toLowerCase()
                    .includes('visit')
            ) {
                let episodeSearch = `fieldSearch=${this.state.allFieldId?.agencyid}=${this.state.agencyid}&fieldSearch=${this.state.allFieldId?.episodeid}=${custom_selected.episodeid}`;
                this.episodeVisitFunction(episodeSearch);
            }
        } else {
            const formData = {
                ...this.state.formData,
                episodeVisit: '',
                episodeid: '',
                select_an_episode: '',
            };
            this.setState({ formData: formData, selectedEpisode: '' }, () => {
                this.updateState({ formData: formData });
            });
        }
    };
    episodeVisitFunction = async (searchText) => {
        if (
            this.state.formData.attachment_location
                ?.toLowerCase()
                .includes('episode') &&
            this.state.formData.attachment_location
                ?.toLowerCase()
                .includes('visit')
        ) {
            this.setState({ refreshEpisodeVisit: false });
            const response = await recordSetSearch(
                this.state.allRecordSetID.episode_visit_data.recordsetId,
                searchText
            );
            let data;

            if (response.status === 200) {
                data = await response.json();
            } else {
                this.commonErrorMessage();
                return;
            }
            if (data) {
                if (data && data?.code === 300) {
                    PostMessageLogout();
                }
                const searchResult = await EpisodeSearch(data);
                this.setState({ visitDate: searchResult });
                if (searchResult.length === 1) {
                    const formData = {
                        ...this.state.formData,
                        ['visit_date']: searchResult[0].visit_date,
                        ['visit_id']: searchResult[0].visit_id,
                    };

                    this.setState({ formData: formData }, () => {
                        this.updateState({ formData: formData });
                    });
                }
                if (searchResult.length === 0) {
                    const formData = {
                        ...this.state.formData,
                        ['visit_id']: '',
                    };

                    this.setState({ formData: formData }, () => {
                        this.updateState({ formData: formData });
                    });
                }
                this.setState({ episodeVisitData: searchResult }, () => {
                    this.setState({
                        refreshEpisodeVisit: true,
                    });
                });
            }
        }
    };

    attachmentLocationApi = async (selectedDDL) => {
        const fieldData = GetFieldData({ configData: this.props.configData });
        let encodeLoc = encodeParameter(selectedDDL);
        let locationSearchText = `?fieldSearch=${fieldData.attachment_type}%3D${encodeLoc}%2A`;
        let branch;
        if (this.props?.newStateCalling === 'upload') {
            branch = this.state?.patient_filter?.branch
                ? this.state.patient_filter.branch
                : this.props.menuBranch;
        } else {
            branch = this.props.inbox_page.currentBranch;
        }
        if (this.props.configData.indexingSettings.locationUsed === true) {
            await this.setState({ agency_id: '' });
            //await this.getAgencyFromBranch(branch);

            // eslint-disable-next-line no-unused-vars
            const agencyid = this.state.agencyid;

            if (this.state.agencyid) {
                locationSearchText += `&fieldSearch=${fieldData.agencyid}%3D${this.state.agencyid}%2A`;
            }

            const response = await getAttachmentLocData(
                fieldData.attachment_location,
                locationSearchText
            );
            let data;
            if (response) {
                data = await response.json();
            }
            if (data) {
                if (response.status === 200) {
                    if (data.values) {
                        await this.props.getDDLlist({
                            ...this.props.ddl,
                            [formatHeaderNameWithOutSpace(data.name)]:
                                data.values,
                        });
                        let attachmentLocationDup = data.values.filter(
                            (element, index, array) =>
                                array.indexOf(element) === index
                        );

                        if (
                            this.props.ddl.hasOwnProperty('attachmentlocation')
                        ) {
                            const form_data = {
                                ...this.state.formData,
                                attachment_location:
                                    attachmentLocationDup.length === 1
                                        ? attachmentLocationDup[0]
                                        : this.state.formData
                                              .attachment_location, // workitem 9202,
                            };

                            await this.setState(
                                {
                                    formData: form_data,
                                    attachmentLocationDDL:
                                        this.props.ddl.attachmentlocation,
                                },
                                () => {
                                    //this.setReqField();
                                    this.setState({
                                        refreshAttachmentLoc: true,
                                    });
                                    this.updateState({ formData: form_data });
                                }
                            );
                            if (this.state.by_patient) {
                                await this.setReqField();
                            }
                            if (
                                this.state.by_order &&
                                attachmentLocationDup.length === 1 &&
                                attachmentLocationDup[0] === 'EPISODE VISIT'
                            ) {
                                let searchText = `fieldSearch=${this.state.allFieldId?.agencyid}=${this.state.formData.agencyid}&fieldSearch=${this.state.allFieldId?.episodeid}=${this.state.formData.episodeid}`;
                                this.episodeVisitFunction(searchText);
                            }
                        }
                        if (
                            this.state.by_order &&
                            attachmentLocationDup.length === 1 &&
                            attachmentLocationDup[0]
                                ?.toLowerCase()
                                .includes('episode') &&
                            attachmentLocationDup[0]
                                ?.toLowerCase()
                                .includes('visit')
                        ) {
                            let searchText = `fieldSearch=${this.state.allFieldId?.agencyid}=${this.state.formData.agencyid}&fieldSearch=${this.state.allFieldId?.episodeid}=${this.state.formData.episodeid}`;
                            this.episodeVisitFunction(searchText);
                        }
                    }
                } else {
                    this.setState({
                        attachmentLocationDDL: [],
                    });
                    this.props.getDDLlist({
                        [formatHeaderNameWithOutSpace('Attachment Location')]:
                            [],
                    });
                    this.commonErrorMessage();
                }
            }
        }
    };

    setFormOnChange = async (state, value, type) => {
        const formData = { ...this.state.formData, [state]: value };
        //if (this._isMounted) {
        await this.setState({ formData: formData }, () => {
            this.updateState({ formData: formData });
        });
        //}
    };

    setNewReferrel = async (state, value, type, view) => {
        await this.setState({
            createPatientData: {}, // added for clear patient details incomplete and yes
            newReferralSet: false,
            selectedEpisode: {},

            episodeData: [],
            referralDate: '',
        });

        if (this.state.isUploadPage) {
            this.props.setEpisodeData([]);
        }

        const formData = {
            [state]: value,
            attachment_type: '',
            attachment_location: '',
            referral_date: '',
        };
        await this.setState(
            {
                formData: formData,
                currentNewReferrel: value,
                attachmentTypeDDL: [],
                attachmentTypeBranch: '',
            },
            () => {
                this.updateState({
                    formData: formData,
                    validateDetail: false,
                });
                if (view !== 'initialView') {
                }
            }
        );
        if (value === 'Yes' || value === 'Incomplete') {
            this.openCreateNewPatient();
            if (this.props.ddl.branch.length === 1) {
                await this.getAgencyFromBranch(this.props.ddl.branch[0]);
                await this.setAttachmentTypeDDL(this.props.ddl.branch[0]);
            }
        } else if (value === 'No') {
            // this.byPatient('initialView');
            this.byPatient();
        }
        setTimeout(() => {
            this.setState({
                newReferralSet: true,
            });
        }, 100);
    };

    byPatient = async (view) => {
        let state = {};
        if (view !== 'initialView') {
            state.patient_filter = {};
            // added by Aiswarya
            this.setState({
                patient_filter: {},
            });
            this.updateState({
                patient_filter: {},
            });
        }
        state = {
            ...state,
            create_patient: false,
            view_patient: false,
            lookupRefresh: false,
            by_patient: true,
            by_order: false,
            view_order: false,
            create_order: false,
            edit_patient: false,
            currentLookup: 'byPatient',
            patient_filter_display: '', // aiswarya added
            selectedEpisode: {},
            attachmentTypeDDL: [],
            attachmentTypeBranch: '',
        };

        if (this.state.isUploadPage === true) {
            const uploadReqFields = ['attachment_type', 'attachment_location'];
            state = { ...state, uploadReqFields: uploadReqFields };
        }
        this.props.updateState({
            byOrder: false,
        });

        await this.setState(state, () => {
            this.updateState(state);
            //  this.setState({ by_patient: true })
        });
        const lookupRefresh = {
            lookupRefresh: true,
        };
        await this.setState(lookupRefresh, () => {
            this.updateState(lookupRefresh);
        });
    };

    byOrder = async (view) => {
        //Aiswarya
        let state = {
            lookupRefresh: false,
            by_patient: false,
            by_order: true,
            edit_order: false,
            view_patient: false,
            create_patient: false,
            currentLookup: 'byOrder',
            validateDetail: false,
            selectedEpisode: {},
            attachmentTypeDDL: [],
            attachmentTypeBranch: '',
        };

        if (view !== 'initialView') {
            state.patient_filter = {};
        }
        if (this.state.isUploadPage === true) {
            const uploadReqFields = ['date_of_birth_validation', 'signed_date'];
            state = { ...state, uploadReqFields: uploadReqFields };
        }
        await this.setState(state, () => {
            this.updateState(state);
        });
        this.props.updateState({
            byOrder: true,
        });
        const lookupRefresh = {
            lookupRefresh: true,
        };
        await this.setState(lookupRefresh, () => {
            this.updateState(lookupRefresh);
        });
    };

    updateState = (state) => {
        if (typeof this.props.updateState === 'function') {
            this.props.updateState(state);
        }
    };

    closeCreatePatient = async (formData) => {
        const state = {
            patient_filter: formData,
            create_patient: false,
            view_patient: true,
            patientIsNegative: false,
            openCreatePopup: false,
        };
        await this.setState(state, async () => {
            this.updateState(state);
        });
    };

    closeCreateOrder = (orderFormData) => {
        this.setState(
            { create_order: false, order_filter: orderFormData },
            () => {
                this.byOrder();
                this.updateState({
                    create_order: false,
                    order_filter: orderFormData,
                });
            }
        );
    };

    closeEditPatient = (formData) => {
        const state = {
            edit_patient: false,
            view_patient: true,
            //patient_filter: formData,
        };
        this.setState(state, () => {
            this.updateState(state);
        });
    };

    closeEditOrder = (formData) => {
        const state = {
            edit_patient: false,
            view_patient: true,
            //patient_filter: formData,
        };
        this.setState(state, () => {
            if (this.state.formData.new_referral === 'No') {
                this.byOrder('initialView');
            }
            this.updateState(state);
        });

        setTimeout(() => {
            this.setState({
                view_patient: true,
            });
        }, 2000);
    };

    openCreateNewPatient = async (view) => {
        await this.setState({ create_patient: false });
        const state = {
            create_patient: true,
            edit_patient: false,
            view_patient: false,
            open_patient_dropdown: false,
            open_order_dropdown: false,
            patientCreated: false,
            // patient_filter: {},
        };
        this.setState(state, () => {
            this.updateState(state);
        });
    };

    submitCreatePatient = () => {
        this.setState({
            submitCreatePatient: true,
        });
    };

    openNewPatient = () => {
        // this.setFormOnChange('new_referral', 'Yes', '');
        this.setState(
            {
                // currentNewReferrel: 'Yes',
                // refreshNewReferrel: false,
                create_patient: true,
            },
            () => {
                // this.setState({ refreshNewReferrel: true });
            }
        );
    };

    setReqField = async () => {
        await this.setState({
            epiosdeReadOnly: false,
        });
        let episodeTxt = '';
        let apiCall = false;
        if (this.state.by_order || this.state.currentLookup === 'byOrder') {
            if (this.state.patient_filter.episodeid) {
                if (
                    (this.state.patient_filter.episode_end &&
                        this.state.patient_filter.episode_start &&
                        this.state.patient_filter.episode_status) ||
                    (this.state.formData.episode_end &&
                        this.state.formData.episode_start &&
                        this.state.formData.episode_status)
                ) {
                    if (
                        (this.state.patient_filter.attachment_location ===
                            'EPISODE' ||
                            this.state.formData.attachment_location ===
                                'EPISODE' ||
                            (this.state.formData.attachment_location
                                ?.toLowerCase()
                                .includes('episode') &&
                                this.state.patient_filter.attachment_location
                                    ?.toLowerCase()
                                    .includes('visit')) ||
                            (this.state.patient_filter.attachment_location
                                ?.toLowerCase()
                                .includes('episode') &&
                                this.state.formData.attachment_location
                                    ?.toLowerCase()
                                    .includes('visit'))) &&
                        this.props.rulesJson.indexingSettings.locationUsed ===
                            true
                    ) {
                        apiCall = true;

                        if (
                            this.state.allFieldId?.episodeid &&
                            this.state.patient_filter &&
                            this.state.patient_filter.episodeid
                        ) {
                            episodeTxt += `&fieldSearch=${this.state.allFieldId.episodeid}=${this.state.patient_filter.episodeid}`;
                        }
                        this.setState({
                            episodeLookup: true,
                        });
                    } else {
                        apiCall = false;
                        this.setState({
                            episodeLookup: false,
                        });
                    }
                } else {
                    //No episode_end, episode_start, episode status
                    apiCall = true; //api called passed pateint id

                    this.setState({
                        episodeLookup: false,
                    });
                }
            } else {
                apiCall = true;
                await this.searchSinglePatient(
                    this.state.patient_filter.patient_id
                );

                if (
                    this.state.allFieldId.episodeid &&
                    this.state.singlePatient &&
                    this.state.singlePatient.episodeid
                ) {
                    episodeTxt += `&fieldSearch=${this.state.allFieldId?.episodeid}=${this.state.singlePatient.episodeid}`;
                }
                this.setState({
                    episodeLookup: false,
                });
            }
        }
        if (this.state.by_patient && this.state.patient_filter.patient_id) {
            if (this.props.rulesJson.indexingSettings.locationUsed === true) {
                if (
                    this.state.formData.attachment_location === 'EPISODE' ||
                    this.getEpisodeLocationValues()
                ) {
                    apiCall = true;
                    this.setState({
                        episodeLookup: true,
                    });
                } else if (
                    this.state.formData.attachment_location &&
                    this.state.formData.attachment_location !== 'EPISODE'
                ) {
                    apiCall = true;
                    await this.searchSinglePatient(
                        this.state.patient_filter.patient_id
                    );

                    if (
                        this.state.allFieldId.episodeid &&
                        this.state.singlePatient &&
                        this.state.singlePatient.episodeid
                    ) {
                        episodeTxt += `&fieldSearch=${this.state.allFieldId?.episodeid}=${this.state.singlePatient.episodeid}`;
                    }
                    this.setState({
                        episodeLookup: false,
                    });
                }
            } else if (
                this.props.rulesJson.indexingSettings.locationUsed === false
            ) {
                apiCall = true;
                this.setState({
                    episodeLookup: false,
                });
            }
        }
        let searchText = '';
        if (
            this.state.allRecordSetID &&
            this.state.allRecordSetID.episode_data &&
            this.state.allRecordSetID.episode_data.inputSearchFieldIds &&
            this.state.allRecordSetID.episode_data.inputSearchFieldIds.length >
                0
        ) {
            const inputSearchfields =
                this.state.allRecordSetID.episode_data.inputSearchFieldIds;
            inputSearchfields.map((inputs, index) => {
                let amb;
                if (index === 0) {
                    amb = '';
                } else {
                    amb = '&';
                }
                if (inputs === this.state.allFieldId.agencyid) {
                    searchText += `${amb}fieldSearch=${inputs}=${this.state.agencyid}`;
                }
                if (inputs === this.state.allFieldId.branch) {
                    searchText += `${amb}fieldSearch=${inputs}=${encodeParameter(
                        this.props.inbox_page.currentBranch
                    )}`;
                }
                if (
                    inputs == this.state.allFieldId.patient_id &&
                    this.state.patient_filter.patient_id
                ) {
                    searchText += `${amb}fieldSearch=${inputs}=${this.state.patient_filter.patient_id}`;
                }
            });
        }

        if (episodeTxt) {
            searchText += episodeTxt;
        }

        let statuses;
        if (
            this.state.agencyid &&
            this.props.inbox_page.currentBranch &&
            this.state.patient_filter.patient_id &&
            apiCall === true
        ) {
            const result = await recordSetSearch(
                this.state.episodeRecordsetId,
                searchText
            );
            var data;

            if (result.status === 200) {
                data = await result.json();
            } else {
                this.commonErrorMessage();
                return;
            }
            if (data) {
                const searchResult = EpisodeSearch(data);

                this.setState({ episodeData: searchResult });
                this.updateState({ episodeData: searchResult });
                if (this.state.isUploadPage) {
                    await this.props.setEpisodeData(searchResult);
                }
            }
        }

        if (this.state.isUploadPage) {
            const uploadReqFields = [
                'attachment_type',
                'attachment_location',
                'select_an_episode',
            ];
            this.setState({ detailReqFields: uploadReqFields });
            this.updateState({ uploadReqFields: uploadReqFields });
        }
        if (
            this.state.isUploadPage === true &&
            this.state.formData.attachment_location
                ?.toLowerCase()
                .includes('episode') &&
            this.state.formData.attachment_location
                ?.toLowerCase()
                .includes('visit')
        ) {
            const uploadReqFields = [
                'attachment_type',
                'attachment_location',
                'select_an_episode',
            ];
            this.setState({ detailReqFields: uploadReqFields });
            this.updateState({ uploadReqFields: uploadReqFields });
        }

        let formData = {};

        if (this.state.episodeData && this.state.episodeData.length === 1) {
            const selectedEpisode = this.state.episodeData[0];

            formData = this.setSelectedEpisode(formData, selectedEpisode);
        } else if (this.state.episodeData.length > 1) {
            const selectedEpisode = this.state.episodeData.find(
                (episode) =>
                    episode.episodeid === this.state.patient_filter.episodeid
            );

            if (selectedEpisode) {
                formData = this.setSelectedEpisode(formData, selectedEpisode);
            } else {
                formData = this.clearSelectedEpisode(formData);
            }
        } else if (this.state.episodeData.length === 0) {
            formData = {
                ...this.state.patient_filter,
                ...this.state.formData,
                select_an_episode: '',
            };
            await this.setState(
                {
                    formData: formData,
                    selectedEpisode: {},
                    //epiosdeReadOnly: true,
                },
                () => {
                    this.updateState({ formData: formData });
                }
            );
        }
    };

    edit_patient = () => {
        this.setState({ edit_patient: true }, () => {
            this.updateState({ edit_patient: true });
        });
    };

    submitEditPatient = async () => {
        await this.setState({
            submitEditPatient: true,
        });
        const state = {
            by_order: false,
            create_patient: false,
            view_patient: true,
            patientIsNegative: false,
            edit_patient: false,
        };
        await this.setState(state, async () => {
            this.updateState(state);
        });
        await this.setState({
            submit_edit_patient: false,
        });
    };

    clearFormData = () => {
        var blankFormData = {
            attachment_type: '',
            attachment_location: '',
            comments: '',
            new_referral: 'No',
            team: '',
            date_of_birth_validation: '',
            signed_date: null,
            episodeid: '',
            episode_visit: '',
            select_an_episode: '',
            episode_status: '',
            episode_start: '',
            episode_end: '',
            medical_record_: '',
        };

        if (
            !_.isEqual(this.state.formData, blankFormData) ||
            this.state.validateDetail
        ) {
            this.setState({
                formData: blankFormData,
                validateDetail: false,
            });
            this.updateState({
                formData: blankFormData,
                validateDetail: false,
            });
            if (this.props?.newStateCalling === 'upload') {
                this.props?.setValidateAttachment(false);
            }
        }
    };

    clearSelectedEpisode(formData) {
        formData = {
            ...this.state.patient_filter,
            ...this.state.formData,
            select_an_episode: '',
        };
        this.setState(
            {
                formData: formData,
                selectedEpisode: {},
            },
            () => {
                this.updateState({ formData: formData });
            }
        );
        return formData;
    }

    setSelectedEpisode(formData, selectedEpisode) {
        formData = {
            ...this.state.patient_filter,
            ...this.state.formData,
            select_an_episode: selectedEpisode.select_an_episode,
            episodeid: selectedEpisode.episodeid,
        };
        formData = {
            ...formData,
            ...selectedEpisode,
        };
        this.setState(
            {
                formData: formData,
                selectedEpisode: selectedEpisode,
                epiosdeReadOnly: true,
            },
            () => {
                this.updateState({ formData: formData });
            }
        );

        if (
            this.state.formData.attachment_location
                ?.toLowerCase()
                .includes('episode') &&
            this.state.formData.attachment_location
                ?.toLowerCase()
                .includes('visit')
        ) {
            let episodeSearch = `fieldSearch=${this.state.allFieldId?.agencyid}=${this.state.agencyid}&fieldSearch=${this.state.allFieldId?.episodeid}=${selectedEpisode.episodeid}`;
            if (episodeSearch) this.episodeVisitFunction(episodeSearch);
        }
        return formData;
    }

    callBack(state) {
        this.setState({
            byOrderEdit: state,
        });
    }

    getEpisodeLocationValues = () => {
        return ['episode', 'visit'].some((location) =>
            this.state.formData.attachment_location
                ?.toLowerCase()
                .includes(location)
        );
    };

    getEpisodeVisibilityStatus = () => {
        var episodeSelected = this.state.selectedEpisode;
        var shouldSelectEpisode = stringEmptyOrUndefined(
            this.state.formData?.select_an_episode
        );
        var attachmentLocationIsEpisode =
            this.state.formData.attachment_location.toUpperCase() === 'EPISODE';
        var isNotReferral =
            this.state.formData?.new_referral.toUpperCase() === 'NO';

        const status =
            (episodeSelected || shouldSelectEpisode) &&
            attachmentLocationIsEpisode &&
            isNotReferral;

        return status;
    };

    shouldShowEpisodeVisit = () => {
        return ['episode', 'visit'].every((location) =>
            this.state.formData.attachment_location
                ?.toLowerCase()
                .includes(location)
        );
    };

    render() {
        const currentNewReferrel = this.state.currentNewReferrel;
        let duplicateAttribute = [];

        return (
            <>
                {this.state.refreshEdit ? (
                    <div className=' mb-2'>
                        {this.state.refreshNewReferrel &&
                        this.props.rulesJson.hasOwnProperty(
                            'indexingSettings'
                        ) &&
                        this.props.rulesJson.indexingSettings.referralsUsed ? (
                            <div className='form-group row'>
                                <div className='col-lg-12'>
                                    <label className='pl-5'>NEW REFERRAL</label>
                                    <div className='tab_part'>
                                        <div className='tabs'>
                                            <Nav
                                                style={{
                                                    float: 'left',
                                                }}
                                                variant='pills'
                                                className='mb-3 nav-item'
                                                defaultActiveKey={
                                                    currentNewReferrel
                                                }
                                            >
                                                {this.props.ddl.hasOwnProperty(
                                                    'newreferral'
                                                ) &&
                                                this.props.ddl.newreferral
                                                    .length > 0
                                                    ? this.props.ddl.newreferral.map(
                                                          (
                                                              newReferral,
                                                              index
                                                          ) => {
                                                              return (
                                                                  <Nav.Item
                                                                      disabled={
                                                                          this
                                                                              .props
                                                                              .isLocked
                                                                      }
                                                                      key={
                                                                          index
                                                                      }
                                                                  >
                                                                      <Nav.Link
                                                                          disabled={
                                                                              this
                                                                                  .props
                                                                                  .isLocked
                                                                          }
                                                                          title={
                                                                              newReferral
                                                                          }
                                                                          key={
                                                                              index
                                                                          }
                                                                          eventKey={
                                                                              newReferral
                                                                          }
                                                                          value={
                                                                              newReferral
                                                                          }
                                                                          id='newReferrel'
                                                                          onClick={() => {
                                                                              if (
                                                                                  !this
                                                                                      .props
                                                                                      .isLocked
                                                                              ) {
                                                                                  this.setNewReferrel(
                                                                                      'new_referral',
                                                                                      newReferral,
                                                                                      ''
                                                                                  );
                                                                              }
                                                                          }}
                                                                      >
                                                                          {
                                                                              newReferral
                                                                          }
                                                                      </Nav.Link>
                                                                  </Nav.Item>
                                                              );
                                                          }
                                                      )
                                                    : null}
                                            </Nav>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ) : null}

                        {(this.state.formData.new_referral === 'No' &&
                            this.state.lookupRefresh) ||
                        (this.props.rulesJson.hasOwnProperty(
                            'indexingSettings'
                        ) &&
                            this.props.rulesJson.indexingSettings
                                .referralsUsed === false) ? (
                            <div className='form-group row byTab'>
                                {this.state.currentLookup !== '' && (
                                    <div className='col-lg-12 col-xl-4'>
                                        <label className='pl-5'>
                                            LOOKUP DETAILS
                                        </label>
                                        <div className='tab_part'>
                                            <div className='tabs'>
                                                <Nav
                                                    style={{
                                                        float: 'left',
                                                    }}
                                                    id='noanim-tab'
                                                    defaultActiveKey={
                                                        this.state.currentLookup
                                                    }
                                                    variant='pills'
                                                    className=''
                                                >
                                                    <Nav.Item>
                                                        <Nav.Link
                                                            title='By Patient'
                                                            key={0}
                                                            eventKey='byPatient'
                                                            disabled={
                                                                this.props
                                                                    .isLocked
                                                            }
                                                            onClick={() => {
                                                                if (
                                                                    !this.props
                                                                        .isLocked
                                                                ) {
                                                                    this.byPatient();
                                                                }
                                                            }}
                                                        >
                                                            By Patient
                                                        </Nav.Link>
                                                    </Nav.Item>
                                                    <Nav.Item>
                                                        <Nav.Link
                                                            title='By Order'
                                                            key={1}
                                                            eventKey='byOrder'
                                                            disabled={
                                                                this.props
                                                                    .isLocked
                                                            }
                                                            onClick={() => {
                                                                if (
                                                                    !this.props
                                                                        .isLocked
                                                                ) {
                                                                    this.byOrder();
                                                                }
                                                            }}
                                                        >
                                                            By Order
                                                        </Nav.Link>
                                                    </Nav.Item>
                                                </Nav>
                                            </div>
                                        </div>
                                    </div>
                                )}

                                {this.state.by_patient ? (
                                    <ByPatient
                                        isLocked={this.props.isLocked}
                                        isUploadPage={this.state.isUploadPage}
                                        state={{ ...this.state }}
                                        updateState={(state) => {
                                            this.setState({ ...state }, () => {
                                                this.updateState({ ...state });
                                            });
                                        }}
                                        editPatient={this.state.edit_patient}
                                        submitEditPatient={() => {
                                            this.setState({
                                                submit_edit_patient: true,
                                            });
                                        }}
                                        openNewPatient={this.openNewPatient}
                                        byOrder={false}
                                        calingFrom='inbox'
                                        patient_filter_display={
                                            this.state.patient_filter_display
                                        }
                                        uploadPatientLink={
                                            this.props.patientLink
                                        }
                                        PatientLink_Function={(e) => {
                                            this.props.PatientLink_Function(e);
                                        }}
                                        newStateCalling={
                                            this.props.newStateCalling
                                        }
                                        patientLink={this.props.patientLink}
                                        referralsUsed={
                                            this.props.rulesJson
                                                .indexingSettings.referralsUsed
                                        }
                                        enableSingleSearch={
                                            this.state.enableSingleSearch
                                        }
                                        documentBranch={
                                            this.state.documentBranch
                                        }
                                        formData={this.state.formData}
                                    />
                                ) : null}

                                {this.state.by_order ? (
                                    <ByOrder
                                        isLocked={this.props.isLocked}
                                        state={{ ...this.state }}
                                        updateState={(state) => {
                                            this.setState({ ...state }, () => {
                                                this.updateState(state);
                                            });
                                        }}
                                        editPatient={this.state.edit_patient}
                                        submitEditPatient={() => {
                                            this.setState({
                                                submit_edit_patient: true,
                                            });
                                        }}
                                        openNewPatient={this.openNewPatient}
                                        byOrder
                                        selectedPatient={
                                            this.state.patient_filter
                                        }
                                        uploadPatientLink={
                                            this.props.patientLink
                                        }
                                        PatientLink_Function={(e) => {
                                            this.props.PatientLink_Function(e);
                                        }}
                                        newStateCalling={
                                            this.props.newStateCalling
                                        }
                                        patientLink={this.props.patientLink}
                                        attachmentLocationApi={(value) => {
                                            this.attachmentLocationApi(value);
                                        }}
                                    />
                                ) : null}
                            </div>
                        ) : null}

                        {this.props.rulesJson.hasOwnProperty(
                            'indexingSettings'
                        ) &&
                        this.props.rulesJson.indexingSettings.referralsUsed ===
                            true &&
                        (this.state.formData.new_referral === 'Yes' ||
                            this.state.formData.new_referral ===
                                'Incomplete') ? (
                            <div className='form-group row'>
                                <div className='col-lg-12'>
                                    <div className='lookup-details'>
                                        <label className='pl-5'>
                                            LOOKUP DETAILS
                                        </label>
                                        <div className='tab_part'>
                                            <div className='tabs'>
                                                <Nav
                                                    style={{
                                                        float: 'left',
                                                    }}
                                                    id='noanim-tab'
                                                    defaultActiveKey='byPatient'
                                                    variant='pills'
                                                    className='inactive'
                                                >
                                                    <Nav.Item>
                                                        <Nav.Link
                                                            title='By Patient'
                                                            key={0}
                                                            eventKey='byPatient'
                                                            onClick={
                                                                this.byPatient
                                                            }
                                                            disabled
                                                        >
                                                            By Patient
                                                        </Nav.Link>
                                                    </Nav.Item>
                                                    <Nav.Item>
                                                        <Nav.Link
                                                            title='By Order'
                                                            key={1}
                                                            eventKey='byOrder'
                                                            onClick={
                                                                this.byOrder
                                                            }
                                                            disabled
                                                        >
                                                            By Order
                                                        </Nav.Link>
                                                    </Nav.Item>
                                                </Nav>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='lookup-outer detail_search'>
                                        <label className='pl-5'>LOOKUP</label>
                                        <div className='d-flex'>
                                            <div className='filter'>
                                                <span className='create_new_patient'>
                                                    Create New Patient
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ) : null}

                        {this.state.view_patient ? (
                            <ViewEditPatient
                                newReferral={this.state.formData.new_referral}
                                resetSubmit={() => {
                                    this.setState({
                                        submit_edit_patient: false,
                                    });
                                }}
                                selectedPatient={this.state.patient_filter}
                                editPatient={this.state.edit_patient}
                                submitEditPatient={
                                    this.state.submit_edit_patient
                                }
                                patientFieldIdArray={
                                    this.state.modifyPatientFieldIdArray
                                }
                                patientModifiableRecordsetId={
                                    this.state
                                        .modifyPatientModifiableRecordsetId
                                }
                                orderFieldIdArray={
                                    this.state.modifyOrderFieldIdArray
                                }
                                orderModifiableRecordsetId={
                                    this.state.modifyOrderModifiableRecordsetId
                                }
                                closeEditPatient={(formData) =>
                                    this.closeEditPatient(formData)
                                }
                                closeEditOrder={(formData) =>
                                    this.closeEditOrder(formData)
                                }
                                updateState={(state) => {
                                    this.setState({ ...state });
                                    this.props.updateState({ ...state });
                                }}
                                byPatient={this.byPatient}
                                byOrder={this.state.by_order}
                                allRecordSetID={this.state.allRecordSetID}
                                updateFromCreate={this.submitEditPatient}
                                open_edit_patient={this.state.open_edit_patient}
                                currentLookup={this.state.currentLookup}
                                updatePatientFilter={(e) => {
                                    if (this.props.updatePatientFilter) {
                                        this.props.updatePatientFilter(e);
                                    }
                                }}
                                byOrderEdit={this.state.byOrderEdit}
                            />
                        ) : null}

                        {this.state.openCreatePopup &&
                        this.state.patientCreated === false ? (
                            // &&(this.state.formData.new_referral === 'Yes' ||
                            //     this.state.formData.new_referral ===
                            //         'Incomplete')
                            <CreateNewPatient
                                closeCreatePatient={(formData) =>
                                    this.closeCreatePatient(formData)
                                }
                                formData={this.state.formData}
                                branches={this.props.branches}
                                patientFieldIdArray={
                                    this.state.patientFieldIdArray
                                }
                                patientModifiableRecordsetId={
                                    this.state.patientModifiableRecordsetId
                                }
                                submitCreatePatient={
                                    this.state.submitCreatePatient
                                }
                                resetSubmit={() => {
                                    this.setState({
                                        submitCreatePatient: false,
                                    });
                                    this.props.updateState({
                                        submitCreatePatient: false,
                                    });
                                }}
                                submittingViaBigView={
                                    this.state.submittingViaBigView
                                }
                                submittingViaUpload={
                                    this.state.submittingViaUpload
                                }
                                updateState={(state) => {
                                    this.updateState({ ...state });
                                }}
                                submitDetailForm={() => {
                                    this.props.submitDetailForm();
                                }}
                                allRecordSetID={this.state.allRecordSetID}
                                allowedFields={this.state.allowedFields}
                                isUploadPage={this.state.isUploadPage}
                                validateForUpload={this.props.validateForUpload}
                                isDetailBigView={this.props.isDetailBigView}
                                open_create_patient={
                                    this.state.open_create_patient
                                }
                                formFor={this.state.formFor}
                                calingFrom='inbox'
                                callBack={(e) => {
                                    this.callBack(e);
                                }}
                                by_order={this.state.by_order}
                                modifyPatientFieldIdArray={
                                    this.state.modifyPatientFieldIdArray
                                }
                            />
                        ) : null}

                        {/* create new patient form */}
                        {this.props.rulesJson.hasOwnProperty(
                            'indexingSettings'
                        ) &&
                        this.props.rulesJson.indexingSettings.referralsUsed ===
                            true &&
                        this.state.create_patient &&
                        this.state.newReferralSet ? (
                            <DetailCreatePatient
                                validateCreate={this.state.validateCreate}
                                closeCreatePatient={(formData) =>
                                    this.closeCreatePatient(formData)
                                }
                                branches={this.props.branches}
                                patientFieldIdArray={
                                    this.state.modifyPatientFieldIdArray
                                }
                                patientModifiableRecordsetId={
                                    this.state
                                        .modifyPatientModifiableRecordsetId
                                }
                                submitCreatePatient={
                                    this.state.submitCreatePatient
                                }
                                resetSubmit={() => {
                                    this.setState({
                                        submitCreatePatient: false,
                                    });
                                    this.props.updateState({
                                        submitCreatePatient: false,
                                    });
                                }}
                                submittingViaBigView={
                                    this.state.submittingViaBigView
                                }
                                submittingViaUpload={
                                    this.state.submittingViaUpload
                                }
                                updateState={(state) => {
                                    this.updateState({ ...state });
                                    this.setState({ ...state });
                                }}
                                submitDetailForm={() => {
                                    this.props.submitDetailForm();
                                }}
                                allRecordSetID={this.state.allRecordSetID}
                                allowedFields={this.state.allowedFields}
                                isUploadPage={this.state.isUploadPage}
                                validateForUpload={this.props.validateForUpload}
                                isDetailBigView={this.props.isDetailBigView}
                                open_create_patient={
                                    this.state.open_create_patient
                                }
                                createPatientData={this.state.createPatientData}
                                formData={this.state.formData}
                                handleFormOnChange={(name, e) =>
                                    this.handleFormOnChange(name, e)
                                }
                            />
                        ) : null}

                        {this.state.create_order ? (
                            <CreateNewOrder
                                closeCreateOrder={(orderFormData) =>
                                    this.closeCreateOrder(orderFormData)
                                }
                                branches={this.props.branches}
                                orderFieldIdArray={
                                    this.state.modifyOrderFieldIdArray
                                }
                                orderModifiableRecordsetId={
                                    this.state.modifyOrderModifiableRecordsetId
                                }
                                submitCreateOrder={this.state.submitCreateOrder}
                                resetSubmit={() => {
                                    this.setState({ submitCreateOrder: false });
                                }}
                            />
                        ) : null}

                        {/* Form Starts */}
                        <div className='row grid-row-group'>
                            {this.props.rulesJson.hasOwnProperty(
                                'indexingSettings'
                            ) &&
                            this.props.rulesJson.indexingSettings
                                .referralsUsed === true &&
                            (this.state.formData.new_referral === 'Yes' ||
                                this.state.formData.new_referral ===
                                    'Incomplete') ? (
                                <>
                                    {(this.props.isDetailBigView &&
                                        this.props.rulesJson.hasOwnProperty(
                                            'indexingSettings'
                                        ) &&
                                        this.props.rulesJson.indexingSettings
                                            .payorSourceUsed) ||
                                    (this.state.isUploadPage &&
                                        this.state.allowedFields.includes(
                                            'payor_source'
                                        ) &&
                                        this.props.rulesJson.hasOwnProperty(
                                            'indexingSettings'
                                        ) &&
                                        this.props.rulesJson.indexingSettings
                                            .payorSourceUsed) ? (
                                        <div className='col-lg-6 transition-select'>
                                            <label className='pl-5'>
                                                PAYOR SOURCE
                                            </label>
                                            <select
                                                id='delete_selectbrand'
                                                className='form-control'
                                                onChange={(event) => {
                                                    this.handleFormOnChange(
                                                        'payor_source',
                                                        event
                                                    );
                                                }}
                                                value={
                                                    this.state.formData.hasOwnProperty(
                                                        'payor_source'
                                                    )
                                                        ? this.state.formData
                                                              .payor_source
                                                        : ''
                                                }
                                            >
                                                <option value=''>Select</option>
                                                {this.props.ddl &&
                                                Array.isArray(
                                                    this.props.ddl.payorsource
                                                )
                                                    ? this.props.ddl.payorsource.map(
                                                          (data, index) => (
                                                              <option
                                                                  key={index}
                                                                  value={data.toUpperCase()}
                                                              >
                                                                  {data.toUpperCase()}
                                                              </option>
                                                          )
                                                      )
                                                    : null}
                                            </select>
                                            <i
                                                className='fa-light fa-chevron-down'
                                                style={{
                                                    pointerEvents: 'none',
                                                }}
                                            />
                                        </div>
                                    ) : null}

                                    {/* Caretype */}
                                    {(this.props.isDetailBigView &&
                                        this.props.rulesJson.hasOwnProperty(
                                            'indexingSettings'
                                        ) &&
                                        this.props.rulesJson.indexingSettings
                                            .careTypeUsed) ||
                                    (this.state.isUploadPage &&
                                        this.state.allowedFields.includes(
                                            'care_type'
                                        ) &&
                                        this.props.rulesJson.hasOwnProperty(
                                            'indexingSettings'
                                        ) &&
                                        this.props.rulesJson.indexingSettings
                                            .careTypeUsed) ? (
                                        <div className='col-lg-6 transition-select'>
                                            <label className='pl-5'>
                                                CARE TYPE
                                            </label>
                                            <select
                                                id='delete_selectbrand'
                                                className='form-control'
                                                onChange={(event) => {
                                                    this.handleFormOnChange(
                                                        'care_type',
                                                        event
                                                    );
                                                }}
                                                value={
                                                    this.state.formData.hasOwnProperty(
                                                        'care_type'
                                                    )
                                                        ? this.state.formData
                                                              .care_type
                                                        : ''
                                                }
                                            >
                                                <option value=''>Select</option>
                                                {this.props.ddl &&
                                                Array.isArray(
                                                    this.props.ddl.caretype
                                                )
                                                    ? this.props.ddl.caretype.map(
                                                          (data, index) => (
                                                              <option
                                                                  key={index}
                                                                  value={data.toUpperCase()}
                                                              >
                                                                  {data.toUpperCase()}
                                                              </option>
                                                          )
                                                      )
                                                    : null}
                                            </select>
                                            <i
                                                className='fa-light fa-chevron-down'
                                                style={{
                                                    pointerEvents: 'none',
                                                }}
                                            />
                                        </div>
                                    ) : null}

                                    {/* TEAM */}
                                    {(this.props.isDetailBigView &&
                                        this.props.rulesJson.hasOwnProperty(
                                            'indexingSettings'
                                        ) &&
                                        this.props.rulesJson.indexingSettings
                                            .teamUsed) ||
                                    (this.state.isUploadPage &&
                                        this.state.allowedFields.includes(
                                            'team'
                                        ) &&
                                        this.props.rulesJson.hasOwnProperty(
                                            'indexingSettings'
                                        ) &&
                                        this.props.rulesJson.indexingSettings
                                            .teamUsed) ? (
                                        <div className='col-lg-6 transition-select'>
                                            <label className='pl-5'>TEAM</label>
                                            <select
                                                id='delete_selectbrand'
                                                className='form-control'
                                                onChange={(event) => {
                                                    this.handleFormOnChange(
                                                        'team',
                                                        event
                                                    );
                                                }}
                                                value={
                                                    this.state.formData.hasOwnProperty(
                                                        'team'
                                                    )
                                                        ? this.state.formData
                                                              .team
                                                        : ''
                                                }
                                            >
                                                <option value=''>Select</option>
                                                {this.props.ddl &&
                                                Array.isArray(
                                                    this.props.ddl.team
                                                )
                                                    ? this.props.ddl.team.map(
                                                          (data, index) => (
                                                              <option
                                                                  key={index}
                                                                  value={data.toUpperCase()}
                                                              >
                                                                  {data.toUpperCase()}
                                                              </option>
                                                          )
                                                      )
                                                    : null}
                                            </select>
                                            <i
                                                className='fa-light fa-chevron-down'
                                                style={{
                                                    pointerEvents: 'none',
                                                }}
                                            />
                                            {this.state.validateDetail &&
                                            this.state.formData.team === '' &&
                                            this.state.detailReqFields.includes(
                                                'team'
                                            ) ? (
                                                <p
                                                    className='error m-0'
                                                    style={{
                                                        color: 'red',
                                                        position: 'absolute',
                                                    }}
                                                >
                                                    Team is required.
                                                </p>
                                            ) : null}
                                        </div>
                                    ) : null}
                                </>
                            ) : null}
                            {((this.state.isUploadPage &&
                                this.state.allowedFields.includes(
                                    'referral_date'
                                )) ||
                                stringEmptyOrUndefined(
                                    this.state.isUploadPage
                                ) == false) &&
                            (this.state.formData.new_referral === 'Yes' ||
                                this.state.formData.new_referral ===
                                    'Incomplete') ? (
                                <div className='col-lg-6 transition-select'>
                                    <label className='pl-5'>
                                        REFERRAL DATE
                                    </label>
                                    <div className='position-relative form-control'>
                                        <DatePicker
                                            format='MM/dd/y'
                                            onChange={(date) => {
                                                this.setDate1(
                                                    'referral_date',
                                                    date
                                                );
                                            }}
                                            value={this.state.referralDate}
                                            className='date-picker'
                                            // format="y-MM-dd"
                                        />
                                    </div>
                                </div>
                            ) : null}
                            <>
                                {/* ATTACHMENT TYPE */}
                                {(this.props.isDetailBigView &&
                                    this.props.rulesJson.hasOwnProperty(
                                        'indexingSettings'
                                    ) &&
                                    this.props.rulesJson.indexingSettings
                                        .attachmentTypeUsed) ||
                                (this.state.isUploadPage &&
                                    this.state.allowedFields.includes(
                                        'attachment_type'
                                    ) &&
                                    this.props.rulesJson.hasOwnProperty(
                                        'indexingSettings'
                                    ) &&
                                    this.props.rulesJson.indexingSettings
                                        .attachmentTypeUsed) ? (
                                    <div className='col-lg-6 transition-select'>
                                        <label className='pl-5'>
                                            {this.props.rulesJson.hasOwnProperty(
                                                'indexingSettings'
                                            ) &&
                                            this.props.rulesJson
                                                .indexingSettings
                                                .attachmentTypeLabel
                                                ? this.props.rulesJson.indexingSettings.attachmentTypeLabel.toUpperCase()
                                                : 'ATTACHMENT TYPE'}
                                        </label>

                                        <select
                                            id='delete_selectbrand'
                                            className={
                                                (this.state.validateDetail &&
                                                    this.state.formData
                                                        .attachment_type ===
                                                        '' &&
                                                    this.state.detailReqFields.includes(
                                                        'attachment_type'
                                                    )) ||
                                                (this.props.newStateCalling ===
                                                    'upload' &&
                                                    this.props
                                                        .validateAttachment &&
                                                    stringEmptyOrUndefined(
                                                        this.state.formData
                                                            .attachment_type
                                                    ) === false)
                                                    ? 'form-control error-field'
                                                    : 'form-control'
                                            }
                                            value={this.state.formData?.attachment_type?.toUpperCase()}
                                            onChange={(event) => {
                                                this.props?.byPatientDynamicUpdate?.setData(); // reset dynamic fields
                                                this.handleAttachmentType(
                                                    'attachment_type',
                                                    event
                                                );
                                            }}
                                            /* disabled={
                                !!this.state.by_order &&
                                this.state
                                    .currentNewReferrel === 'No'
                            } */
                                        >
                                            <option value=''>Select</option>
                                            {this.state.attachmentTypeDDL
                                                .filter(
                                                    (element, index, array) =>
                                                        array.indexOf(
                                                            element
                                                        ) === index
                                                )
                                                .map((options, index) => {
                                                    return (
                                                        <option
                                                            value={options.toUpperCase()}
                                                            key={index}
                                                        >
                                                            {options}
                                                        </option>
                                                    );
                                                })}
                                        </select>
                                        <i
                                            className='fa-light fa-chevron-down'
                                            style={{ pointerEvents: 'none' }}
                                        />
                                        {(this.state.validateDetail &&
                                            this.state.formData
                                                .attachment_type === '' &&
                                            this.state.detailReqFields.includes(
                                                'attachment_type'
                                            )) ||
                                        (this.props.newStateCalling ===
                                            'upload' &&
                                            this.props.validateAttachment &&
                                            stringEmptyOrUndefined(
                                                this.state.formData
                                                    .attachment_type
                                            ) === false) ? (
                                            <p
                                                className='error m-0'
                                                style={{
                                                    color: 'red',
                                                    // position: 'absolute',
                                                }}
                                            >
                                                {this.props.rulesJson.hasOwnProperty(
                                                    'indexingSettings'
                                                ) &&
                                                this.props.rulesJson
                                                    .indexingSettings
                                                    .attachmentTypeLabel !== ''
                                                    ? this.props.rulesJson
                                                          .indexingSettings
                                                          .attachmentTypeLabel
                                                    : 'Attachment Type'}{' '}
                                                is required.
                                            </p>
                                        ) : null}
                                    </div>
                                ) : null}

                                {/* ATTACHMENT LOCATION */}
                                {(this.props.isDetailBigView &&
                                    this.props.rulesJson.hasOwnProperty(
                                        'indexingSettings'
                                    ) &&
                                    this.props.rulesJson.indexingSettings
                                        .locationUsed &&
                                    this.state.refreshAttachmentLoc) ||
                                (this.state.refreshAttachmentLoc &&
                                    this.state.isUploadPage &&
                                    this.state.allowedFields.includes(
                                        'attachment_location'
                                    ) &&
                                    this.props.rulesJson.hasOwnProperty(
                                        'indexingSettings'
                                    ) &&
                                    this.props.rulesJson.indexingSettings
                                        .locationUsed) ? (
                                    <div className='col-lg-6 transition-select'>
                                        <label className='pl-5'>
                                            {' '}
                                            {this.props.rulesJson.hasOwnProperty(
                                                'indexingSettings'
                                            ) &&
                                            this.props.rulesJson
                                                .indexingSettings.locationLabel
                                                ? this.props.rulesJson.indexingSettings.locationLabel.toUpperCase()
                                                : 'ATTACHMENT LOCATION'}
                                        </label>
                                        <select
                                            id='delete_selectbra'
                                            className={
                                                (this.state.validateDetail &&
                                                    (this.state.formData
                                                        .attachment_location ===
                                                        '' ||
                                                        '') &&
                                                    this.state.detailReqFields.includes(
                                                        'attachment_location'
                                                    )) ||
                                                (this.props.newStateCalling ===
                                                    'upload' &&
                                                    this.props
                                                        .validateAttachment &&
                                                    stringEmptyOrUndefined(
                                                        this.state.formData
                                                            .attachment_location
                                                    ) === false)
                                                    ? 'form-control error-field'
                                                    : 'form-control'
                                            }
                                            value={this.state.formData.attachment_location.toUpperCase()}
                                            onChange={(event) => {
                                                this.handleFormOnChange(
                                                    'attachment_location',
                                                    event
                                                );
                                            }}
                                        >
                                            <option value=''>Select</option>
                                            {this.state.formData
                                                .attachment_type === ''
                                                ? null
                                                : this.props.ddl
                                                      .attachmentlocation !==
                                                      undefined &&
                                                  this.props.ddl.attachmentlocation
                                                      .filter(
                                                          (
                                                              element,
                                                              index,
                                                              array
                                                          ) =>
                                                              array.indexOf(
                                                                  element
                                                              ) === index
                                                      )
                                                      .map((options, key) => {
                                                          return (
                                                              <option
                                                                  value={options.toUpperCase()}
                                                                  key={key}
                                                              >
                                                                  {options}
                                                              </option>
                                                          );
                                                      })}
                                        </select>
                                        <i
                                            className='fa-light fa-chevron-down'
                                            style={{ pointerEvents: 'none' }}
                                        />
                                        {(this.state.validateDetail &&
                                            (this.state.formData
                                                .attachment_location === '' ||
                                                this.state.formData
                                                    .attachment_type === '') &&
                                            this.state.detailReqFields.includes(
                                                'attachment_location'
                                            )) ||
                                        (this.props.newStateCalling ===
                                            'upload' &&
                                            this.props.validateAttachment &&
                                            stringEmptyOrUndefined(
                                                this.state.formData
                                                    .attachment_location
                                            ) === false) ? (
                                            <p
                                                className='error m-0'
                                                style={{
                                                    color: 'red',
                                                    // position: 'absolute',
                                                }}
                                            >
                                                {this.props.rulesJson.hasOwnProperty(
                                                    'indexingSettings'
                                                ) &&
                                                this.props.rulesJson
                                                    .indexingSettings
                                                    .locationLabel !== ''
                                                    ? this.props.rulesJson
                                                          .indexingSettings
                                                          .locationLabel
                                                    : 'Attachment Location'}{' '}
                                                is required.
                                            </p>
                                        ) : null}
                                    </div>
                                ) : null}

                                {this.state.refreshAttachmentLoc === false ? (
                                    <div
                                        className='col-lg-6 transition-select'
                                        style={{
                                            textAlign: 'center',
                                            marginTop: '20px',
                                        }}
                                    >
                                        <Spinner
                                            animation='border'
                                            variant='primary'
                                        />
                                    </div>
                                ) : null}
                            </>
                            <>
                                {/* EPISODE */}
                                {this.getEpisodeVisibilityStatus() ? (
                                    <div className='col-lg-6 transition-select'>
                                        <label className='pl-5'>EPISODE</label>
                                        <select
                                            id='delete_selectbrand'
                                            className='form-control'
                                            value={
                                                this.state.formData.hasOwnProperty(
                                                    'select_an_episode'
                                                )
                                                    ? this.state.formData
                                                          .select_an_episode
                                                    : ''
                                            }
                                            onChange={(event) => {
                                                this.handleEpisodeChange(
                                                    'select_an_episode',
                                                    event.target.value
                                                );
                                            }}
                                            disabled={
                                                this.state.by_order &&
                                                this.state.epiosdeReadOnly
                                            }
                                        >
                                            <option value=''>Select</option>
                                            {this.state.episodeData &&
                                                this.state.episodeData.map(
                                                    (options, key) => {
                                                        if (
                                                            options.select_an_episode !==
                                                            ''
                                                        ) {
                                                            return (
                                                                <option
                                                                    value={
                                                                        options.select_an_episode
                                                                    }
                                                                    key={key}
                                                                >
                                                                    {
                                                                        options.select_an_episode
                                                                    }
                                                                </option>
                                                            );
                                                        }
                                                    }
                                                )}
                                        </select>
                                        <i
                                            className='fa-light fa-chevron-down'
                                            style={{
                                                pointerEvents: 'none',
                                            }}
                                        />
                                    </div>
                                ) : null}

                                {/* EPISODE VISIT */}
                                {this.shouldShowEpisodeVisit() &&
                                this.state.refreshEpisodeVisit ? (
                                    <div className='col-lg-6 transition-select'>
                                        <label className='pl-5'>
                                            EPISODE VISIT
                                        </label>
                                        <select
                                            id='delete_selectbrand'
                                            className={
                                                this.state.validateDetail &&
                                                this.state.formData.visit_id ===
                                                    '' &&
                                                this.state.detailReqFields.includes(
                                                    'visit_id'
                                                )
                                                    ? 'form-control error-field'
                                                    : 'form-control'
                                            }
                                            value={
                                                this.state.formData.hasOwnProperty(
                                                    'visit_id'
                                                )
                                                    ? this.state.formData
                                                          .visit_id
                                                    : ''
                                            }
                                            onChange={(event) => {
                                                this.handleFormOnChange(
                                                    'visit_id',
                                                    event
                                                );
                                            }}
                                        >
                                            <option value=''>Select</option>
                                            {this.state.episodeVisitData !==
                                                undefined &&
                                                this.state.episodeVisitData.map(
                                                    (options, key) => {
                                                        return (
                                                            <option
                                                                value={
                                                                    options.visit_id
                                                                }
                                                                key={key}
                                                            >
                                                                {
                                                                    options.visit_label
                                                                }
                                                            </option>
                                                        );
                                                    }
                                                )}
                                        </select>
                                        <i
                                            className='fa-light fa-chevron-down'
                                            style={{ pointerEvents: 'none' }}
                                        />
                                        {this.state.validateDetail &&
                                        this.state.formData.episode_visit ===
                                            '' &&
                                        this.state.detailReqFields.includes(
                                            'episode_visit'
                                        ) ? (
                                            <p
                                                className='error m-0'
                                                style={{ color: 'red' }}
                                            >
                                                Episode Visit is required.
                                            </p>
                                        ) : null}
                                    </div>
                                ) : null}
                                {this.state.refreshEpisodeVisit === false &&
                                this.state.formData.attachment_location
                                    ?.toLowerCase()
                                    .includes('episode') &&
                                this.state.formData.attachment_location
                                    ?.toLowerCase()
                                    .includes('visit') ? (
                                    <div
                                        className='col-lg-6 transition-select'
                                        style={{
                                            textAlign: 'center',
                                            marginTop: '20px',
                                        }}
                                    >
                                        <Spinner
                                            animation='border'
                                            variant='primary'
                                        />
                                    </div>
                                ) : null}

                                {/* DATE OF BIRTH VALIDATION */}
                                {/* Static  Date of birth removed */}

                                {/* SIGNED DATE */}
                                {this.state.by_order &&
                                this.state.formData.new_referral === 'No' ? (
                                    <div className='col-lg-6 transition-select'>
                                        <label className='pl-5'>
                                            SIGNED DATE
                                        </label>
                                        <div
                                            className={
                                                this.props?.orderByValidation?.validationMap?.has?.(
                                                    'signed_date'
                                                )
                                                    ? 'position-relative form-control error-field'
                                                    : 'position-relative form-control'
                                            }
                                        >
                                            <DatePicker
                                                format='MM/dd/y'
                                                onChange={(date) => {
                                                    this?.props?.orderByValidation?.removeValidation(
                                                        'signed_date'
                                                    );
                                                    this.setDate(
                                                        'signed_date',
                                                        date
                                                    );
                                                }}
                                                value={
                                                    this.state.formData.hasOwnProperty(
                                                        'signed_date'
                                                    )
                                                        ? this.state.formData
                                                              .signed_date
                                                        : null
                                                }
                                                className='date-picker'
                                            />
                                        </div>
                                        {this.props?.orderByValidation?.validationMap?.has?.(
                                            'signed_date'
                                        ) && (
                                            <p
                                                className='error m-0'
                                                style={{
                                                    color: 'red',
                                                }}
                                            >
                                                {this.props.orderByValidation.validationMap.get(
                                                    'signed_date'
                                                )}
                                            </p>
                                        )}
                                    </div>
                                ) : null}
                            </>
                            <>
                                {/* Dynamic Form UI  */}
                                {this.props.selectedRowDetails?.miscPproperties
                                    ?.rulesJson &&
                                    this.state.formData.new_referral === 'No' &&
                                    (this.state.currentLookup === 'byPatient' ||
                                        this.state.currentLookup ===
                                            'byOrder') &&
                                    Object.entries(
                                        (() => {
                                            var rule =
                                                typeof this.props
                                                    .selectedRowDetails
                                                    .miscPproperties
                                                    .rulesJson == 'object'
                                                    ? this.props
                                                          .selectedRowDetails
                                                          .miscPproperties
                                                          .rulesJson
                                                          .ValidationRules
                                                    : JSON.parse(
                                                          this.props
                                                              .selectedRowDetails
                                                              .miscPproperties
                                                              .rulesJson
                                                      ).ValidationRules;

                                            var afterChangeJSON =
                                                JSON.stringify(rule);

                                            afterChangeJSON =
                                                afterChangeJSON.replace(
                                                    /Location Type/g,
                                                    'Attachment Location'
                                                );

                                            return JSON.parse(afterChangeJSON);
                                        })()
                                    ).map((value, index) => {
                                        // eslint-disable-next-line

                                        var property1 = value[0]
                                            .replace('(', '')
                                            .replace(')', '')
                                            .split(',');
                                        var property = [
                                            formatNameWithSpaceToUnderscore(
                                                property1[0].trim()
                                            ),
                                            property1[1],
                                        ];
                                        if (
                                            property[0].trim() in
                                                this.state.formData &&
                                            this.state.formData[
                                                property[0].trim()
                                            ].toLowerCase() ==
                                                property[1].trim().toLowerCase()
                                        ) {
                                            return this.state?.duplicateAttribute.map(
                                                (attributerefs, index) => {
                                                    if (
                                                        duplicateAttribute.includes(
                                                            attributerefs
                                                        )
                                                    ) {
                                                        return null;
                                                    }

                                                    if (
                                                        formatNameWithSpaceToUnderscore(
                                                            attributerefs
                                                        ) in
                                                        this.props.state
                                                            .patient_filter
                                                    ) {
                                                        duplicateAttribute.push(
                                                            attributerefs
                                                        );
                                                        // Don't need to render fields,if already in patient_filter
                                                        return null;
                                                    }

                                                    duplicateAttribute.push(
                                                        attributerefs
                                                    );
                                                    return (
                                                        <>
                                                            {formatNameWithSpaceToUnderscore(
                                                                attributerefs
                                                            ) in
                                                                this.state
                                                                    .patient_filter ===
                                                            false ? (
                                                                <FormFileds
                                                                    errorMessage={this.props.orderByValidation.validationMap.get(
                                                                        formatNameWithSpaceToUnderscore(
                                                                            attributerefs
                                                                        )
                                                                    )}
                                                                    key={index}
                                                                    fieldName={
                                                                        attributerefs
                                                                    }
                                                                    value={
                                                                        this.props.byPatientDynamicUpdate?.data?.get(
                                                                            formatNameWithSpaceToUnderscore(
                                                                                attributerefs
                                                                            )
                                                                        ) ||
                                                                        this
                                                                            .state
                                                                            .formData[
                                                                            formatNameWithSpaceToUnderscore(
                                                                                attributerefs
                                                                            )
                                                                        ]
                                                                    }
                                                                    onChange={(
                                                                        value
                                                                    ) => {
                                                                        this.props.orderByValidation.removeValidation(
                                                                            formatNameWithSpaceToUnderscore(
                                                                                attributerefs
                                                                            )
                                                                        );
                                                                        this.props.byPatientDynamicUpdate?.setData(
                                                                            formatNameWithSpaceToUnderscore(
                                                                                attributerefs
                                                                            ),
                                                                            value
                                                                        );
                                                                    }}
                                                                />
                                                            ) : null}
                                                        </>
                                                    );
                                                }
                                            );
                                        }

                                        return null;
                                    })}
                            </>
                            <div className='episode-container-stretcher'>
                                {this.getEpisodeVisibilityStatus() && (
                                    <ViewEpisode
                                        selectedEpisode={
                                            this.state.selectedEpisode
                                        }
                                    />
                                )}
                            </div>
                            {this.props.isDetailBigView === true ||
                            (this.state.isUploadPage &&
                                this.state.allowedFields.includes(
                                    'comments'
                                )) ? (
                                <>
                                    <div className='col-lg-12'>
                                        <label className='pl-5'>COMMENTS</label>
                                        <textarea
                                            className='form-control'
                                            value={
                                                this.state.formData.hasOwnProperty(
                                                    'comments'
                                                )
                                                    ? this.state.formData
                                                          .comments
                                                    : ''
                                            }
                                            maxLength='250'
                                            onChange={(event) => {
                                                this.handleFormOnChange(
                                                    'comments',
                                                    event
                                                );
                                            }}
                                        />
                                        <div className='maximum-length'>
                                            <span className='float-left'>
                                                Maximum Length: 250
                                            </span>
                                            {this.state.commentTextFlag ===
                                            1 ? (
                                                <span className='float-right'>
                                                    Remaining:{' '}
                                                    {
                                                        this.state
                                                            .commentTextCount
                                                    }
                                                </span>
                                            ) : (
                                                ' '
                                            )}
                                        </div>
                                        {this.state.validateDetail &&
                                        this.state.formData.comments === '' &&
                                        this.state.detailReqFields.includes(
                                            'comments'
                                        ) ? (
                                            <p
                                                className='error m-0'
                                                style={{
                                                    color: 'red',
                                                    position: 'absolute',
                                                }}
                                            >
                                                Comments is required.
                                            </p>
                                        ) : null}
                                    </div>
                                </>
                            ) : null}
                        </div>
                    </div>
                ) : (
                    <div className='text-center mt-15'>
                        <div className='spinner-border' role='status'>
                            <span className='sr-only'>Loading...</span>
                        </div>
                    </div>
                )}
            </>
        );
    }
}

const mapDispatchToProps = {
    getDDLlist: getDDLlist,
    editDetailPage,
    inboxPage,
};
const mapStateToProps = (state, ownProps) => {
    return {
        ddl: state.persistDDL.DDL,
        configuredRecordsets:
            state.configReducerPersist.configData.configuredRecordsets,
        selectedRowDetails: state.inbox.selectedRowDetails,
        configData: state.configReducerPersist.configData,
        rulesJson: state.configReducerPersist.configData,
        uploadTypes: state.configReducerPersist.configData.uploadTypes,
        edit_detail_page: state.inbox.editDetailPage,
        inbox_page: state.inbox.inboxPage,
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(EditDetails));
